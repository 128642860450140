import { useState } from "react";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { Filters, useSearchParamForm } from "~components/filters";
import Table from "~components/table";
import { CertificateGroupStatus } from "~features/inventory";
import { AuthorizedSidebar } from "~features/navigation";
import {
  EstimationType,
  OwnershipType,
  PortfolioFilters,
  PortfolioTableFilters,
  useInventoryGroupPortfolioQuery,
  usePortfolioTable,
} from "~features/portfolio";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const PortfolioView = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [query, setQuery] = useQueryParams({
    vintageYear: withDefault(StringParam, undefined),
    ownership: withDefault(
      createEnumParam(Object.values(OwnershipType)),
      undefined,
    ),
    availability: withDefault(
      createEnumParam<
        CertificateGroupStatus.ALLOCATED | CertificateGroupStatus.SOLD
      >([CertificateGroupStatus.SOLD, CertificateGroupStatus.ALLOCATED]),
      undefined,
    ),
    estimation: withDefault(
      createEnumParam(Object.values(EstimationType)),
      undefined,
    ),
    verraBufferEnabled: withDefault(StringParam, undefined),
  });

  const {
    verraBufferEnabled: _verraBufferEnabled,
    ...queryWithoutVerraBufferEnabled
  } = query;

  const { data, isLoading } = useInventoryGroupPortfolioQuery({
    filters: {
      ...queryWithoutVerraBufferEnabled,
      vintageYear: query.vintageYear,
    },
    options: {
      verraBufferEnabled: query.verraBufferEnabled,
    },
  });

  const table = usePortfolioTable({
    data,
    isVerraRiskBufferEnabled: Boolean(query.verraBufferEnabled),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<PortfolioFilters>(query, setQuery, () =>
    setIsFiltersOpen(false),
  );

  const renderFilterBarItem = (
    key: keyof PortfolioFilters,
    value: PortfolioFilters[keyof PortfolioFilters],
  ) => {
    const label = (
      {
        vintageYear: "Vintage Year",
        ownership: "Ownership",
        availability: "Availability",
        estimation: "Estimation",
        verraBufferEnabled: "Verra Buffer Enabled",
      } as unknown as Record<keyof PortfolioFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Portfolio</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <PortfolioTableFilters
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          query={query}
          onClear={handleClearFiltersDrawer}
          onSubmit={handleSubmitFilters}
        />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={queryWithoutVerraBufferEnabled}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>
        <Table instance={table} isLoading={isLoading} meta={undefined} />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default PortfolioView;
