import { Select } from "@ag/design-system/molecules";
import I18n from "@ag/i18n";
import { UserRole, UserValidationState } from "@ag/utils/types";

/**
 * Get the label for a user role.
 * @param {UserRole | undefined} role - The user role.
 * @returns {string} The label for the user role.
 */
export const getUserRoleLabel = (role: UserRole | undefined): string => {
  if (!role) return "";

  const labelByUserRole = {
    [UserRole.Farmer]: I18n.t("js.user.user_type.farmer"),
    [UserRole.InsightsCustomer]: I18n.t("js.user.user_type.insights_customer"),
  };

  return labelByUserRole[role] || "";
};

/**
 * Get the label for a user validation state.
 * @param {UserValidationState} validationState - The user validation state.
 * @returns {string} The label for the user validation state.
 */
export const getUserValidationStateLabel = (
  validationState: UserValidationState,
): string => {
  const labelByUserValidationState = {
    [UserValidationState.Validated]: I18n.t(
      "js.user.user_validation_state.validated",
    ),
    [UserValidationState.NotValidated]: I18n.t(
      "js.user.user_validation_state.non_validated",
    ),
    [UserValidationState.Revalidate]: I18n.t(
      "js.user.user_validation_state.revalidated",
    ),
    [UserValidationState.Waiting]: I18n.t(
      "js.user.user_validation_state.waiting",
    ),
  };

  return labelByUserValidationState[validationState] || "";
};

/**
 * Transform an enum object to labels.
 * @param {Record<string, string>} enumObj - The enum object.
 * @returns {Record<string, string>} The transformed labels.
 */
export function transformEnumToLabels(
  enumObj: Record<string, string>,
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(enumObj).map(([_, value]) => [
      value,
      value
        .replace(/[-_]/g, " ")
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" "),
    ]),
  );
}

/**
 * Transform a label value.
 * @param {string | number | undefined} value - The value to transform.
 * @returns {string} The transformed label value.
 */
export const transformedLabelValue = (
  value: string | number | undefined,
): string => {
  if (value === undefined) {
    return "";
  }

  return String(value)
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/[-_]/g, " ")
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Render enum options as labels.
 * @param {object} enumObj - The enum object.
 * @param {string[]} [filter] - Optional array of enum values to include.
 * @returns {JSX.Element[]} The rendered enum options.
 */
export const renderEnumOptionsLabel = (
  enumObj: object,
  filter?: string[],
): JSX.Element[] => {
  const values = filter
    ? Object.values(enumObj).filter(value => filter.includes(value))
    : Object.values(enumObj);
  return values.map(value => (
    <Select.Option key={value} value={value}>
      {value
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/[-_]/g, " ")
        .split(" ")
        .map(
          (word: string) =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(" ")}
    </Select.Option>
  ));
};
