import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse, MetaArgs } from "@ag/utils/types";

import {
  Portfolio,
  PortfolioFilters,
  PortfolioSchema,
} from "../entities/inventory-portfolio";

type InventoryGroupPortfolioParams = MetaArgs & {
  filters?: PortfolioFilters;
  options?: {
    verraBufferEnabled: string | undefined;
  };
};

export const generateInventoryGroupPortfolioQueryKey = (keyVariables?: {
  params?: InventoryGroupPortfolioParams;
}) => ["inventory-portfolio", keyVariables].filter(Boolean);

const getInventoryGroupPortfolio = async (
  params?: InventoryGroupPortfolioParams,
) => {
  const response = await axios.get<CommonResponse<Portfolio>>(
    "/api/v1/inventory/certificate-groups/aggregation",
    {
      params: {
        ...params,
      },
      schema: PortfolioSchema,
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useInventoryGroupPortfolioQuery = (
  params?: InventoryGroupPortfolioParams,
) =>
  useQuery(
    generateInventoryGroupPortfolioQueryKey({ params }),
    () => getInventoryGroupPortfolio(params),
    {
      keepPreviousData: true,
    },
  );
