import {
  DeepKeys,
  RowData,
  TableOptions,
  getCoreRowModel as baseGetCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffectOnceWhen } from "rooks";

import { TextCell } from "../../cells/text-cell";
import { getGroupedState } from "../helpers";

// Reworks column pinning to add deep keys type safety based on row data.
type TableStateWithDeepColumnPinning<T extends RowData> = Omit<
  NonNullable<TableOptions<T>["state"]>,
  "columnPinning"
> & {
  columnPinning?: {
    left?: (DeepKeys<T> | "select")[];
    right?: (DeepKeys<T> | "select")[];
  };
};

export type UseTableOptions<T extends RowData> = Omit<
  TableOptions<T>,
  | "getCoreRowModel"
  | "state"
  | "groupedColumnMode"
  | "enableGrouping"
  | "manualSorting"
> & {
  getCoreRowModel?: TableOptions<T>["getCoreRowModel"];
  state?: TableStateWithDeepColumnPinning<T>;
  groupVisibility?: Record<string, boolean>;
  groupedColumnMode?: "remove" | "reorder" | false | undefined;
  enableGrouping?: boolean;
};

const DEFAULT_MAX_COLUMN_SIZE = 280;

export type TableState<T extends RowData> = UseTableOptions<T>["state"];

export const useTable = <T>({
  getCoreRowModel = baseGetCoreRowModel(),
  defaultColumn,
  columns,
  state,
  groupVisibility = {},
  groupedColumnMode = "remove",
  enableGrouping = true,
  ...rest
}: UseTableOptions<T>) => {
  const instance = useReactTable<T>({
    // defaults
    defaultColumn: {
      cell: TextCell,
      size: 0,
      minSize: 0,
      enableResizing: false,
      enablePinning: false,
      maxSize: DEFAULT_MAX_COLUMN_SIZE,
      ...(defaultColumn ?? {}),
    },
    getCoreRowModel,
    enableRowSelection: false,
    enableMultiRowSelection: false,

    // state
    columns,
    state: {
      ...state,
      columnPinning: {
        left:
          state?.columnPinning?.left?.map(key => (key ?? "").toString()) ?? [],
        right:
          state?.columnPinning?.right?.map(key => (key ?? "").toString()) ?? [],
      },
    },

    // sorting
    manualSorting: true,

    // grouping settings needed for our hide/show behavior
    groupedColumnMode,
    enableGrouping,

    // filtering
    manualFiltering: true,

    // rest
    ...rest,
  });

  const instanceHeaders = instance.getFlatHeaders();
  const instanceColumns = instanceHeaders.map(header => header.column);

  // Toggle grouped state to match group visibility or default to closed.
  useEffectOnceWhen(() => {
    instanceColumns.forEach(column => {
      const groupedState = getGroupedState(column, enableGrouping);

      // We only care about the first column in a group.
      if (!groupedState.isGrouped || !groupedState.isFirstInGroup) return;

      // If configured to show by default, we are done.
      if (groupVisibility[column.id] === true) return;

      // Toggle the remaining columns of the group, removing them, and leaving only the first visible.
      groupedState.columns
        .slice(1)
        .forEach(subColumn => subColumn.toggleGrouping());
    });
  }, Boolean(groupVisibility));

  const pinEnabledColumns = instanceColumns.filter(
    column => column.getIsPinned() || column.getCanPin(),
  );

  const noSizeStickyColumn = pinEnabledColumns.find(
    column => !column.getSize(),
  );

  // Validation of defs starts here
  const tooDeepHeader = instanceHeaders.find(header => header.depth > 2);
  if (tooDeepHeader) {
    throw new Error(
      `column: "${tooDeepHeader.id}" is within a nested column group, please only use a single level of column groups.`,
    );
  }

  if (noSizeStickyColumn) {
    throw new Error(
      [
        `column: "${noSizeStickyColumn.id}" is set up as sticky column, but has no explicit size.`,
        "Please set a size in column definition.",
      ].join(" "),
    );
  }

  const sizeAboveMaxColumn = instanceColumns.find(column => {
    const { size, maxSize } = column.columnDef;
    return maxSize && size && size > maxSize;
  });

  if (sizeAboveMaxColumn) {
    throw new Error(
      [
        `column: "${sizeAboveMaxColumn.id}" is set up with size ${sizeAboveMaxColumn.columnDef.size}, ` +
          `which is above the maxSize value ${sizeAboveMaxColumn.columnDef.maxSize}`,
        "You want to: A) set maxSize instead of size, B) lower size OR c) raise/set maxSize.",
      ].join("\n"),
    );
  }

  return instance;
};
