import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { BuyoutContractStatus } from "~features/buyout-agreements";
import { ResaleContractStatus } from "~features/resale-agreements";

import {
  CertificateGroupStatus,
  ContractType,
  Inventory,
  InventorySchema,
} from "../entities/inventory";

export type ContractStatus = BuyoutContractStatus | ResaleContractStatus;

type InventoryCertificateGroupsParams = MetaArgs & {
  filters?: Partial<{
    importId?: string;
    fieldId?: string;
    status?: CertificateGroupStatus[];
    vintageYear?: string[];
    countryCode?: string[];
    cropTypeName?: string[];
    isoEligible?: string;
    verraEligible?: string;
    contractType?: ContractType;
    contractStatus?: ContractStatus;
    contractId?: string;
    userId?: string;
  }>;
  sort?: Partial<Record<keyof Inventory, SortingDirection | undefined>>;
};

export const generateInventoryCertificateGroups = (
  params?: InventoryCertificateGroupsParams | undefined,
) => ["inventory-certificate-groups", params].filter(Boolean);

const getInventoryCertificateGroups = async (
  params: InventoryCertificateGroupsParams | undefined,
) => {
  const response = await axios.get<CommonPaginatedResponse<Inventory>>(
    "/api/v1/inventory/certificate-groups",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(InventorySchema),
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useInventoryCertificateGroupsQuery = (
  params?: InventoryCertificateGroupsParams,
) =>
  useQuery(
    generateInventoryCertificateGroups(params),
    () => getInventoryCertificateGroups(params),
    {
      keepPreviousData: true,
    },
  );
