import { ChipVariant } from "@ag/design-system/atoms";

import { HistoryActionType } from "../entities/inventory-history";

export const getHistoryActionTypeChip = (
  type: HistoryActionType,
): { variant: ChipVariant; label: string } => ({
  variant: {
    [HistoryActionType.INSERT]: "success" as ChipVariant,
    [HistoryActionType.DELETE]: "danger" as ChipVariant,
    [HistoryActionType.UPDATE]: "info" as ChipVariant,
  }[type],
  label: {
    [HistoryActionType.INSERT]: "Insert",
    [HistoryActionType.DELETE]: "Delete",
    [HistoryActionType.UPDATE]: "Update",
  }[type],
});
