import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import {
  InventoryWithAssets,
  InventoryWithAssetsSchema,
} from "../entities/inventory";

export const generateInventoryDetailsQueryKey = (id: string) =>
  ["inventory-details", id].filter(Boolean);

const getInventoryCertificateGroupDetails = async (id: string) => {
  const response = await axios.get<CommonResponse<InventoryWithAssets>>(
    `/api/v1/inventory/certificate-groups/${id}`,
    {
      schema: InventoryWithAssetsSchema,
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useInventoryCertificateGroupDetailsQuery = (id: string) =>
  useQuery(
    generateInventoryDetailsQueryKey(id),
    () => getInventoryCertificateGroupDetails(id),
    {
      keepPreviousData: true,
    },
  );
