// Main abilities (Ruby) - values are coming from API
export enum CarbonAbility {
  CalculatePrice = "calculatePrice",
  CancelSubscription = "cancelSubscription",
  CancelSubscriptionNow = "cancelSubscriptionNow",
  Combine = "combine",
  Consent = "consent",
  Contract = "contract",
  CqaUpdate = "cqaUpdate",
  CqcUpdate = "cqcUpdate",
  Create = "create",
  CreateBulk = "createBulk",
  CheckValidity = "checkValidity",
  CheckOwnership = "checkOwnership",
  Currencies = "currencies",
  Delete = "delete",
  DeleteBulk = "deleteBulk",
  Destroy = "destroy",
  DestroyOnStripe = "destroyOnStripe",
  DistanceCalculation = "distanceCalculation",
  ExecuteOrder = "executeOrder",
  Invoke = "invoke",
  List = "list",
  ListOwn = "listOwn",
  LocalesCollection = "localesCollection",
  LoginAs = "loginAs",
  MoneyCalculation = "moneyCalculation",
  Potential = "potential",
  PublishableKey = "publishableKey",
  ReactivateSubscription = "reactivateSubscription",
  Read = "read",
  ReadStatus = "readStatus",
  ReadOwn = "readOwn",
  RecalculateAllStatistic = "recalculateAllStatistic",
  RequestResetPassword = "requestResetPassword",
  ResendConfirmation = "resendConfirmation",
  ResetPassword = "resetPassword",
  Resolve = "resolve",
  Retire = "retire",
  Run = "run",
  SetLocale = "setLocale",
  SetRoles = "setRoles",
  SignInAs = "signInAs",
  SwitchSubscription = "switchSubscription",
  Submit = "submit",
  Sync = "sync",
  Terminate = "terminate",
  Transfer = "transfer",
  TranslatedParities = "translatedParities",
  TranslatedShouldPay = "translatedShouldPay",
  TranslatedUserTypes = "translatedUserTypes",
  TranslatedValidationStates = "translatedValidationStates",
  Unassign = "unassign",
  Unlock = "unlock",
  Update = "update",
  UpdateOwn = "updateOwn",
  UpdateBulk = "updateBulk",
  Validate = "validate",
  VatLookup = "vatLookup",
  Void = "void",
  WaitWithValidation = "waitWithValidation",
  Withdraw = "withdraw",
}

// Auth abilities (Node) - values are coming from API
export enum AuthAbility {
  ValidateAccessToken = "validateAccessToken",
  Create = "create",
  Delete = "delete",
  List = "list",
  Read = "read",
  SendResetPasswordEmail = "sendResetPasswordEmail",
  ResetPassword = "resetPassword",
  DeleteOwn = "deleteOwn",
  UnassignRole = "unassignRole",
  UpdateOwn = "updateOwn",
  LoginAsUser = "loginAsUser",
  AssignRole = "assignRole",
  Update = "update",
  CreateOwn = "createOwn",
  ReadOwn = "readOwn",
  RefreshOwn = "refreshOwn",
  Validate = "validate",
  ValidateOwn = "validateOwn",
  ListOwn = "listOwn",
}

// User management abilities (Node) - values are coming from API
export enum UserManagementAbility {
  Create = "create",
  Delete = "delete",
  Read = "read",
  List = "list",
  ListOwn = "listOwn",
  Update = "update",
  ValidateUser = "validateUser",
  LoginAsUser = "loginAsUser",
}

// Markets abilities - values are coming from API
export enum MarketsAbility {
  Create = "create",
  Delete = "delete",
  Read = "read",
  List = "list",
  ListOwn = "listOwn",
  Submit = "submit",
  Update = "update",
  Publish = "publish",
  DiscardOwn = "discardOwn",
  StartProcessingOwn = "startProcessingOwn",
  DeleteBulk = "deleteBulk",
  Check = "check",
}
