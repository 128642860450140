import { ChipVariant } from "@ag/design-system/atoms";

import { OwnershipType } from "../entities/inventory";

export const getOwnershipTypeChip = (
  type: OwnershipType,
): { variant: ChipVariant; label: string } => ({
  variant: {
    [OwnershipType.Agreena]: "success" as ChipVariant,
    [OwnershipType.Farmer]: "info" as ChipVariant,
  }[type],
  label: {
    [OwnershipType.Agreena]: "Agreena",
    [OwnershipType.Farmer]: "Farmer",
  }[type],
});
