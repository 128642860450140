import { z } from "zod";

export enum ResaleContractStatus {
  Sent = "sent",
  Signed = "signed",
  Withdrawn = "withdrawn",
  Declined = "declined",
  Terminated = "terminated",
}

export enum TerminateReasons {
  OTHER = "other",
}

export const ResaleAgreementSchema = z.object({
  id: z.string(),
  userId: z.string(),
  year: z.number(),
  status: z.nativeEnum(ResaleContractStatus),
  signerName: z.string(),
  companyName: z.string(),
  countryName: z.string(),
  contractDetails: z.object({
    brokerFee: z.string().optional(),
    minimumPricePerUnit: z.string().optional(),
    contactName: z.string().optional(),
    farmerId: z.string().optional(),
    benefitContractIds: z.array(z.string()).optional(),
  }),
  docusignEnvelope: z
    .object({
      id: z.string(),
      docusignEnvelopeId: z.string(),
      docusignEnvelopeStatus: z.string().optional(),
      deliveredAt: z.string().nullable(),
      expiresAt: z.string(),
      sentAt: z.string(),
      signedAt: z.string().nullable(),
    })
    .nullable(),
  documents: z.array(
    z.object({
      type: z.string(), // ??
      attachment: z.object({
        filename: z.string(),
        url: z.string(),
        contentType: z.string(),
      }),
    }),
  ),
  createdAt: z.string(),
  updatedAt: z.string(),
  // transferAgreementUrl: z.string(),
});

export type ResaleAgreement = z.infer<typeof ResaleAgreementSchema>;
