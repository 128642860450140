// Main resource classes (Ruby + Node) - values are coming from API
export enum CarbonResourceClass {
  Actuals = "Actuals",
  ActiveStorage = "ActiveStorage::Blob",
  Address = "Address",
  Admin = "Admin",
  AdminRole = "AdminRole",
  ApplicationSetting = "ApplicationSetting",
  CarbonAdminInitialResources = "CarbonAdminInitialResources",
  CarbonBrokerContract = "CarbonBrokerContract",
  CarbonContract = "CarbonContract",
  CarbonCropType = "CarbonCropType",
  CarbonQcAction = "CarbonQcAction",
  CarbonCountry = "CarbonCountry",
  CarbonFarm = "CarbonFarm",
  CarbonFertiliser = "CarbonFertiliser",
  CarbonField = "CarbonField",
  CarbonFieldActual = "CarbonFieldActual",
  CarbonFieldDefinition = "CarbonFieldDefinition",
  CarbonFieldDefinitionCropProtection = "CarbonFieldDefinitionCropProtection",
  CarbonFieldDefinitionFertiliser = "CarbonFieldDefinitionFertiliser",
  CarbonHummingbirdFieldV2022LocationData = "CarbonHummingbirdFieldV2022LocationData",
  CarbonInitialResources = "CarbonInitialResources",
  CarbonQCAction = "CarbonQCAction",
  CarbonQualityAssurance = "CarbonQualityAssurance",
  CarbonQualityAssuranceAction = "CarbonQualityAssuranceAction",
  CarbonQualityAssuranceComment = "CarbonQualityAssuranceComment",
  CarbonQualityControl = "CarbonQualityControl",
  CarbonQualityControlComment = "CarbonQualityControlComment",
  CarbonSalesAgreement = "CarbonSalesAgreement",
  Offer = "Offer",
  CarbonUser = "CarbonUser",
  CarbonValidationRequest = "CarbonValidationRequest",
  Comment = "Comment",
  Company = "Company",
  Contract = "Contract",
  Country = "Country",
  DocuSign = "DocuSign",
  DocusignConsentUrl = "DocusignConsentUrl",
  ExternalCompany = "ExternalCompany",
  FreightAndTradeSetting = "FreightAndTradeSetting",
  MarketCertificate = "MarketCertificate",
  MarketCertificateGroup = "MarketCertificateGroup",
  MarketCertificateUpdateRequest = "MarketCertificateUpdateRequest",
  MRVRequestResults = "MRVRequestResults",
  OutputsAdminInitialResources = "OutputsAdminInitialResources",
  OutputsInitialResources = "OutputsInitialResources",
  OutputsPriceInsights = "OutputsPriceInsights",
  OutputsStatsUserAddresses = "OutputsStatsUserAddresses",
  Permission = "Permission",
  Profile = "Profile",
  PublicActivityActivity = "PublicActivity::Activity",
  Region = "Region",
  Review = "Review",
  Seen = "Seen",
  Segment = "Segment",
  SegmentUser = "SegmentUser",
  StatsBusinesssEntities = "StatsBusinesssEntities",
  SupportedContractLocale = "SupportedContractLocale",
  HubspotCarbonStats = "HubspotCarbonStats",
  User = "User",
  UserType = "UserType",
  VerifiedCreditUnit = "VerifiedCreditUnit",
  CarbonMrvAnalysisRequest = "CarbonMrvAnalysisRequest",
  CarbonFieldMrvBoundary = "CarbonFieldMrvBoundary",
  FieldBoundaryCheckResult = "FieldBoundaryCheckResult",
  InsightsEmissions = "InsightsEmissions",
  InsightsFieldPractices = "InsightsFieldPractices",
  StaticFile = "StaticFile",
  YearsData = "YearsData",
  UncontractedField = "UncontractedField",

  // this is a webhook, ignore it
  MrvWebhook = "MrvWebhook",

  // this is for the health check, ignore it
  HealthCheck = "HealthCheck",
}

// Auth resource classes (Node) - values are coming from API
export enum AuthResourceClass {
  AdminAccessToken = "AdminAccessToken",
  User = "User",
  Admin = "Admin",
  AdminRole = "AdminRole",
  Permission = "Permission",

  // this is for the health check, ignore it
  HealthCheck = "HealthCheck",
}

// User management resource classes (Node) - values are coming from API
export enum UserManagementResourceClass {
  User = "User",
  Country = "Country",
  Activity = "Activity",
  Company = "Company",
  FreightAndTradeSetting = "FreightAndTradeSetting",
  AdminUserAssignment = "AdminUserAssignment",
  Vat = "Vat",
  InitialResources = "InitialResources",
  Permission = "Permission",

  //this is for the health check, ignore it
  HealthCheck = "HealthCheck",
}

// Markets abilities - values are coming from API
export enum MarketsResourceClass {
  Admin = "Admin",
  AccessToken = "AccessToken",
  ApplicationSetting = "ApplicationSetting",
  Client = "Client",
  ClientDocument = "ClientDocument",
  CertificateGroup = "CertificateGroup",
  CertificateUpdateRequest = "CertificateUpdateRequest",
  Country = "Country",
  HealthCheck = "HealthCheck",
  Permission = "Permission",
  PublicCertificateGroup = "PublicCertificateGroup",
  StaticFile = "StaticFile",
  VerifiedCreditUnit = "VerifiedCreditUnit",
  InventoryImport = "InventoryImport",
  InventoryCertificateGroup = "InventoryCertificateGroup",
  InventoryCertificateGroupHistory = "InventoryCertificateGroupHistory",
  InventoryAssetCalculation = "InventoryAssetCalculation",

  // this is for the webhook, ignore it
  InventoryWebhookEventNotification = "InventoryWebhookEventNotification",

  Authorization = "Authorization",
}
