/**
 * `useSearchParamForm` is a custom hook that provides functionality for managing and clearing search parameters in a form, specifically those represented as query parameters in the URL.
 *
 * @template T The type of the query object.
 * @param {T} query The current state of the search parameters, typically derived from the URL's query parameters.
 * @param {(newValue: Record<string, string | undefined>, updateType?: UpdateType) => void} setQuery Function to update the state of the search parameters, and reflects those changes in the URL's query parameters and into components.
 * @param {() => void} reset Function to reset the pagination.
 *
 * @returns {UseSearchParamForm} An object containing two functions:
 * - `handleClearFiltersBar(targetKey?: TargetKey)`: Clears the filter(s) specified by `targetKey`. If `targetKey` is undefined, all filters are cleared since they are going to be multiple of them. After clearing the filters, it updates the query state (and the corresponding URL query parameters) using the `setQuery` function with the `pushIn` update type. This one is used for the Filter Bar Component
 * - `handleClearFiltersDrawer()`: Clears all filters and then updates the query state (and the corresponding URL query parameters) using the `setQuery` function with the `pushIn` update type. After that, it resets the pagination by calling the `reset` function. This one is used for the Filter Drawer Component.
 * - `handleSubmitFilters()` is a function that handles the submission of filters. It first replaces falsy entries with undefined values in the data with the `replaceEntryWithUndefined` helper function. Then it updates the query state (and the corresponding URL query parameters) using the `setQuery` function. After that, it resets the pagination by calling the `reset` function.
 */
import replaceEntryWithUndefined from "../helpers/replace-entry-with-undefined";

type UpdateType = "replaceIn" | "pushIn" | "replace" | "push";
type TargetKey = string | string[] | undefined;

type UseSearchParamForm<T> = {
  handleClearFiltersBar: (targetKey?: TargetKey) => void;
  handleClearFiltersDrawer: () => void;
  handleSubmitFilters: (data: T) => void;
};

const useSearchParamForm = <
  T extends Record<string, string | number | undefined>,
>(
  query: Partial<T>,
  setQuery: (
    newValue: Record<string, string | number | undefined>,
    updateType?: UpdateType,
  ) => void,
  reset?: () => void,
  onCloseFilters?: () => void,
): UseSearchParamForm<T> => {
  const newQuery: Partial<T> = { ...query };

  const handleClearFiltersBar = (targetKey?: TargetKey) => {
    if (typeof targetKey === "undefined") {
      Object.keys(newQuery).forEach(key => {
        newQuery[key as keyof T] = undefined;
      });
    } else if (typeof targetKey === "string") {
      newQuery[targetKey as keyof T] = undefined;
    }
    setQuery(newQuery, "pushIn");
  };

  const handleClearFiltersDrawer = () => {
    Object.keys(newQuery).forEach(key => {
      newQuery[key as keyof T] = undefined;
    });
    setQuery(newQuery, "pushIn");
    if (reset) {
      reset();
    }

    if (typeof onCloseFilters === "function") {
      onCloseFilters();
    }
  };

  const handleSubmitFilters = (data: T) => {
    const definedFilters = replaceEntryWithUndefined(data);

    setQuery(definedFilters);
    if (reset) {
      reset();
    }

    if (typeof onCloseFilters === "function") {
      onCloseFilters();
    }
  };

  return {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  };
};

export default useSearchParamForm;
