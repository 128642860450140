import { ChipVariant } from "@ag/design-system/atoms";

import { CertificateGroupStatus, ContractType } from "../entities/inventory";

export function getCertificateGroupStatusChipVariant(
  status: CertificateGroupStatus,
): ChipVariant {
  const lookup: Record<CertificateGroupStatus, ChipVariant> = {
    [CertificateGroupStatus.IMPORTED]: "info",
    [CertificateGroupStatus.ALLOCATED]: "success",
    [CertificateGroupStatus.ISSUED]: "warning",
    [CertificateGroupStatus.SOLD]: "neutral",
  };

  return lookup[status];
}

export function getCertificateGroupStatusChipText(
  status: CertificateGroupStatus,
): string {
  const lookup: Record<CertificateGroupStatus, string> = {
    [CertificateGroupStatus.IMPORTED]: "Imported",
    [CertificateGroupStatus.ALLOCATED]: "Allocated",
    [CertificateGroupStatus.ISSUED]: "Issued",
    [CertificateGroupStatus.SOLD]: "Sold",
  };

  return lookup[status];
}

export const getCertificateGroupStatusChip = (
  status: CertificateGroupStatus,
): { variant: ChipVariant; label: string } => ({
  variant: getCertificateGroupStatusChipVariant(status),
  label: getCertificateGroupStatusChipText(status),
});

export const getContractUrl = (
  contractId: string | number | null,
  contractType: ContractType | null,
): string | undefined => {
  const lookup: Record<ContractType, string> = {
    [ContractType.Buyout]: `/carbon/agreements/buyout/${contractId}`,
    [ContractType.Resale]: `/carbon/agreements/resale/${contractId}`,
  };

  return contractType ? lookup[contractType] : undefined;
};
