import { z } from "zod";

export enum HistoryActionType {
  INSERT = "INSERT",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

export const CertificateGroupHistorySchema = z.object({
  id: z.string(),
  originId: z.string(),
  adminId: z.string().nullable(),
  createdAt: z.string(),
  action: z.nativeEnum(HistoryActionType),
  previousSnapshot: z.string(),
  snapshot: z.string(),
});

export type CertificateGroupHistory = z.infer<
  typeof CertificateGroupHistorySchema
>;

export const CertificateGroupHistoryResponseSchema = z.object({
  meta: z.object({
    itemCount: z.number(),
    totalItems: z.number(),
    itemsPerPage: z.number(),
    totalPages: z.number(),
    currentPage: z.number(),
  }),
  items: z.array(CertificateGroupHistorySchema),
});

export type CertificateGroupHistoryResponse = z.infer<
  typeof CertificateGroupHistoryResponseSchema
>;
