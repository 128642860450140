import { Button } from "@ag/design-system/atoms";
import { Card } from "@ag/design-system/organisms";
import { cn } from "@ag/design-system/utils";

import { useUserQuery } from "~features/user";

import { SalesOfferDetails } from "../entities/sales-offer-details";

type Props = Pick<
  SalesOfferDetails,
  "userId" | "fieldsTotalSizeHa" | "companyVatNumber"
>;

const ContactDetailsCard = ({
  userId,
  fieldsTotalSizeHa,
  companyVatNumber,
}: Props) => {
  const { data } = useUserQuery(userId);

  const rows = [
    { label: "User ID", value: userId, href: `/users/${userId}` },
    { label: "Name", value: data?.name },
    { label: "Email", value: data?.email },
    { label: "Phone number", value: data?.profile?.phoneNumber },
    { label: "Total Ha under management", value: fieldsTotalSizeHa },
    { label: "Company VAT", value: companyVatNumber },
  ];

  return (
    <Card className="w-full">
      <>
        <p className="text-p1 font-bold">Contact details</p>

        <div
          className="mt-2 w-full flex-1 overflow-auto rounded bg-white-100"
          data-testid="contact-details"
        >
          <div className="flex flex-wrap gap-4 break-words pt-2 md:grid md:grid-cols-6">
            {rows.map(({ label, value, href }) => (
              <div className="flex flex-col gap-1" key={label}>
                <span className="text-p2 text-grey-800">{label}</span>
                <span
                  className={cn({
                    "text-p3 text-satellite-200 underline": Boolean(href),
                    "text-p2 text-grey-900": !href,
                  })}
                >
                  {!href ? (
                    value ?? "N/A"
                  ) : (
                    <Button to={href} variant="text" size="x-small">
                      {value ?? "N/A"}
                    </Button>
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </>
    </Card>
  );
};

export default ContactDetailsCard;
