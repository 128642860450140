import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { Modal, Table, useTable } from "@ag/design-system/organisms";

import { FieldOverview } from "~features/field/entities/field-overview";

import { RemovalReason } from "../entities/contract";
import {
  convertLocalToUTCDate,
  convertUTCToLocalDate,
} from "../helpers/convert-utc-local-date";
import * as styles from "./remove-fields-from-contract-modal.css";

type RemoveFieldsFromContractModalProps = {
  isOpen: boolean;
  selectedFields: FieldOverview[];
  onRequestClose: () => void;
  onRemoveFields: (
    carbonFieldIds: string[],
    reasonForRemoval: RemovalReason,
    churnedAt?: string,
  ) => void;
};

export const RemoveFieldsFromContractModal = ({
  isOpen,
  selectedFields,
  onRequestClose,
  onRemoveFields,
}: RemoveFieldsFromContractModalProps) => {
  const [removalReason, setRemovalreason] = useState<
    RemovalReason | undefined
  >();
  const [churnedAtDate, setChurnedAtDate] = useState<Date | null>(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Field ID",
        cell: (info: { row: unknown; getValue: () => unknown }) =>
          info.getValue(),
      },
      {
        accessorKey: "userId",
        header: "User ID",
        cell: (info: { row: { original: { user: { id: string } } } }) =>
          info.row.original.user.id,
      },
      {
        accessorKey: "companyName",
        header: "Company Name",
        cell: (info: {
          row: { original: { user: { company: { name: string } } } };
        }) => info.row.original.user.company.name,
      },
      {
        accessorKey: "fieldName",
        header: "Field name",
        cell: (info: { row: { original: { name: string } } }) =>
          info.row.original.name,
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      selectedFields.map(field => ({
        ...field,
        userId: field.user.id,
        companyName: field.user.company.name,
        fieldName: field.name,
      })),
    [selectedFields],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  return (
    <Modal.Root
      isOpen={isOpen}
      onRequestClose={() => {
        setRemovalreason(undefined);
        setChurnedAtDate(null);
        onRequestClose();
      }}
    >
      <Modal.Header>
        <Modal.Title>Remove fields from contract</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <Table instance={tableInstance} />
        <div className={styles.removalOptionsContainer}>
          <div className={styles.removalOption}>
            <p className="w-32 text-p2">Reason for removal</p>
            <Select.Root
              optionsClassName="z-modal"
              value={removalReason}
              onChange={value => {
                setRemovalreason(value as RemovalReason);
              }}
            >
              <Select.Option value={RemovalReason.Duplicate}>
                Duplicate
              </Select.Option>
              <Select.Option value={RemovalReason.Churn}>Churn</Select.Option>
            </Select.Root>
          </div>

          {removalReason == RemovalReason.Churn && (
            <div className={styles.removalOption}>
              <p className="w-32 text-p2">Churned at</p>
              <div>
                <DatePicker
                  selected={convertUTCToLocalDate(churnedAtDate)}
                  onChange={date =>
                    setChurnedAtDate(convertLocalToUTCDate(date))
                  }
                  maxDate={convertUTCToLocalDate(new Date())}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Content>

      <Modal.Footer
        render={onClose => (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button
              isDanger
              disabled={
                !removalReason ||
                (removalReason == RemovalReason.Churn && !churnedAtDate)
              }
              onClick={() =>
                onRemoveFields(
                  selectedFields.map(field => field.id.toString()),
                  removalReason!,
                  churnedAtDate ? churnedAtDate.toUTCString() : undefined,
                )
              }
            >
              Remove fields from contract
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
};
