import { z } from "zod";

export const MetaSchema = z.object({
  itemCount: z.number(),
  totalItems: z.number(),
  itemsPerPage: z.number(),
  totalPages: z.number(),
  currentPage: z.number(),
});

export type Meta = z.infer<typeof MetaSchema>;

export type APIResult<TData, TMetaOrBool extends Meta | boolean = false> = {
  result: {
    data: TData;
    meta: TMetaOrBool extends true
      ? Meta
      : TMetaOrBool extends false
        ? undefined
        : TMetaOrBool;
  };
};

/**
 * @deprecated Use `APIResult` instead together with axios.(get|post|put|delete)
 * Pass `APIResult<SomeThing, true>` for default Meta, otherwise pass custom Meta.
 */
export type APIResponse<TData, TMetaOrBool extends Meta | boolean = false> = {
  data: APIResult<TData, TMetaOrBool>;
  headers: Record<string, string>;
};

export type APINoContentResponse = {
  data: null;
  headers: Record<string, string>;
};

export enum APIErrorStatus {
  NotFound = 404,
}

export type MetaArgs = {
  page?: number | null | undefined;
  limit?: number | null | undefined;
  includeMeta?: boolean;
};

// use-query-params defines too complicated types for params
export type MetaUseQueryParamsArgs = {
  page?: unknown;
  limit?: unknown;
};

// API response for Node apps - to be replaced with shared DTO package from API
export interface CommonResponse<T> {
  success: {
    statusCode: number;
    data: T;
  } | null;
  error: {
    statusCode: number;
    name: string;
    messages: string[];
  } | null;
}

export interface CommonPaginatedResponse<T> {
  success: {
    statusCode: number;
    data: {
      items: T[];
      meta: Meta;
    };
  } | null;
  error: {
    statusCode: number;
    name: string;
    messages: string[];
  } | null;
}

export const createPaginatedDataSchema = <ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType,
) =>
  z.object({
    items: z.array(itemSchema),
    meta: MetaSchema,
  });
