import { createContext, useContext } from "react";

import { ButtonSelectValue } from "./types";

type ButtonSelectContextData<TValue extends ButtonSelectValue> = {
  value: TValue | null | undefined;
  isDisabled: boolean;
  onChange: (value: TValue) => void;
  isInvalid: boolean;
  size: "medium" | "small";
  fit: "inline" | "stretch";
};

export const ButtonSelectContext = createContext<
  ButtonSelectContextData<ButtonSelectValue>
>({
  value: undefined,
  isDisabled: false,
  onChange: () => {},
  isInvalid: false,
  size: "medium",
  fit: "inline",
});

export const useButtonSelectContext = <TValue extends ButtonSelectValue>() => {
  const context = useContext<ButtonSelectContextData<TValue>>(
    ButtonSelectContext as unknown as React.Context<
      ButtonSelectContextData<TValue>
    >,
  );
  if (!context) {
    throw new Error(
      "useButtonSelectContext must be used under ButtonSelectProvider",
    );
  }
  return context;
};

type ProviderProps<TValue extends ButtonSelectValue> = React.PropsWithChildren<
  ButtonSelectContextData<TValue>
>;
export const ButtonSelectProvider = <TValue extends ButtonSelectValue>({
  children,
  value,
  isDisabled,
  onChange,
  isInvalid,
  size,
  fit,
}: ProviderProps<TValue>) => {
  const ButtonSelectContextWithGenericValue =
    ButtonSelectContext as unknown as React.Context<
      ButtonSelectContextData<TValue>
    >;

  return (
    <ButtonSelectContextWithGenericValue.Provider
      value={{ value, isDisabled, onChange, isInvalid, size, fit }}
    >
      {children}
    </ButtonSelectContextWithGenericValue.Provider>
  );
};
