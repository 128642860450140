/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Icon, Spinner } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { EmptyState } from "@ag/design-system/organisms";
import { SelectField } from "@ag/form-fields";

import BackButton from "~components/BackButton";
import {
  InventoryTimeline,
  InventoryTimelineDetails,
  useCertificateGroupHistoryInfinityQuery,
  useInventoryCertificateGroupDetailsQuery,
} from "~features/inventory";
import { getFilteredItems } from "~features/inventory/helpers/parse-snapshots";
import { AuthorizedSidebar } from "~features/navigation";
import {
  CarbonResourceClass,
  UserManagementResourceClass,
  useCarbonPermissions,
  useUserManagementPermissions,
} from "~features/permission";
import OverviewLayout from "~layouts/overview-layout";

const InventoryHistory = () => {
  const { id } = useParams<{ id: string }>();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const navigate = useNavigate();

  if (!id) {
    navigate("/inventory-list");
  }

  const { data: userManagementPermissions } = useUserManagementPermissions();
  const hasUserPermission = userManagementPermissions?.read?.includes(
    UserManagementResourceClass.User,
  );

  const { data: carbonPermissions } = useCarbonPermissions();
  const hasCropTypePermissions = carbonPermissions?.read?.includes(
    CarbonResourceClass.CarbonCropType,
  );
  const hasFieldPermissions = carbonPermissions?.read?.includes(
    CarbonResourceClass.CarbonField,
  );
  const hasContractsPermissions = carbonPermissions?.read?.includes(
    CarbonResourceClass.CarbonContract ||
      CarbonResourceClass.CarbonSalesAgreement,
  );

  const permissions = {
    fieldId: !!hasFieldPermissions,
    userId: !!hasUserPermission,
    contractId: !!hasContractsPermissions,
    cropTypeId: !!hasCropTypePermissions,
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useCertificateGroupHistoryInfinityQuery(id!!, {
      sort: {
        createdAt: sortOrder,
      },
    });

  const { data: mainDetails } = useInventoryCertificateGroupDetailsQuery(id!!);

  const items = useMemo(() => {
    return data?.pages.flatMap(page => page?.items) || [];
  }, [data]);

  const totalItems = useMemo(
    () => data?.pages[0]?.meta.totalItems || 0,
    [data],
  );

  const { filteredItems } = getFilteredItems(items);

  if (isLoading && !items.length) {
    return (
      <OverviewLayout.Root>
        <OverviewLayout.Sidebar>
          <AuthorizedSidebar />
        </OverviewLayout.Sidebar>

        <OverviewLayout.TopBar>
          <OverviewLayout.TopBarTitle>
            {`Inventory Group ${id}`}
          </OverviewLayout.TopBarTitle>
          <BackButton onClick={() => navigate("/inventory-list")} />
        </OverviewLayout.TopBar>

        <OverviewLayout.Content>
          <div className="grid h-full w-full place-items-center">
            <Spinner className="w-10 text-satellite-200" />
          </div>
        </OverviewLayout.Content>
      </OverviewLayout.Root>
    );
  }

  return (
    <OverviewLayout.Root>
      <OverviewLayout.Sidebar>
        <AuthorizedSidebar />
      </OverviewLayout.Sidebar>

      <OverviewLayout.TopBar>
        <OverviewLayout.TopBarTitle>
          {`Inventory Group ${id}`}
        </OverviewLayout.TopBarTitle>
        <BackButton onClick={() => navigate("/inventory-list")} />
      </OverviewLayout.TopBar>

      <OverviewLayout.Content>
        {filteredItems.length > 0 ? (
          <div className="mx-auto grid max-w-[1200px] grid-rows-[auto,auto,1fr] gap-12">
            <div className="row-start-1">
              <div className="flex w-full flex-col gap-6">
                {mainDetails && (
                  <InventoryTimelineDetails
                    data={mainDetails}
                    permissions={permissions}
                  />
                )}
              </div>
            </div>
            <div className="row-start-2">
              <h3 className="pb-4 text-h3 font-bold">History</h3>
              <div className="flex items-center gap-4">
                <SelectField
                  value={sortOrder}
                  optionsClassName="z-modal"
                  onChange={value => setSortOrder(value as "asc" | "desc")}
                >
                  <Select.Option value="desc">
                    <div className="flex items-center gap-1">
                      <span>Most recent on top</span>
                      <Icon name="arrow-down-wide-short" variant="solid" />
                    </div>
                  </Select.Option>
                  <Select.Option value="asc">
                    <div className="flex items-center gap-1">
                      <span>Oldest on top</span>
                      <Icon name="arrow-up-wide-short" variant="solid" />
                    </div>
                  </Select.Option>
                </SelectField>
              </div>
            </div>
            <div className="row-start-3">
              <InventoryTimeline
                timelineData={filteredItems}
                sortOrder={sortOrder}
                permissions={permissions}
              />
            </div>
          </div>
        ) : (
          <EmptyState.Root className="h-full flex-1 justify-center">
            <EmptyState.Image />
            <EmptyState.Title>No Details Available</EmptyState.Title>
            <EmptyState.Description>
              <span className="block text-p1">
                There are no details available for this import.
              </span>
              <span className="text-p1 font-bold">Please retry</span>
            </EmptyState.Description>
          </EmptyState.Root>
        )}
        {filteredItems.length < totalItems && hasNextPage && (
          <div className="flex justify-center py-4">
            <Button
              variant="primary"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? "Loading..." : "Load More"}
            </Button>
          </div>
        )}
      </OverviewLayout.Content>
    </OverviewLayout.Root>
  );
};

export default InventoryHistory;
