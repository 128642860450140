import Table from "~components/table";

import { InventoryWithAssets } from "../entities/inventory";
import { renderValue } from "../helpers/history-values";
import { formatKey } from "../helpers/parse-snapshots";
import { useHistoryAssetsTable } from "../hooks/use-history-assets-table";

const InventoryAdditionalDetails: React.FC<{
  data?: InventoryWithAssets["assetCalculations"];
  permissions: { [key: string]: boolean };
}> = ({ data }) => {
  const table = useHistoryAssetsTable(data || []);
  if (!data) return null;

  return (
    <div className="shadow-md w-full rounded bg-white-100 p-4">
      <h2 className="mb-4 text-h2">Assets Calculation</h2>
      <Table
        instance={table}
        meta={{
          itemCount: data.length,
          totalItems: data.length,
          itemsPerPage: data.length,
          totalPages: 1,
          currentPage: 1,
        }}
      />
    </div>
  );
};

const InventoryTimelineDetails: React.FC<{
  data?: InventoryWithAssets;
  permissions: { [key: string]: boolean };
}> = ({ data, permissions }) => {
  if (!data) return null;

  const contractDetails = [
    "contractId",
    "contractStatus",
    "contractType",
    "contractUpdatedAt",
  ];

  const importDetails = [
    "ownership",
    "status",
    "vintageYear",
    "isoEligible",
    "verraEligible",
  ];

  const fieldDetails = [
    "fieldId",
    "companyVatNumber",
    "countryCode",
    "cropTypeId",
    "cropTypeName",
    "harvestYear",
    "userId",
  ];

  const renderDetails = (keys: string[]) =>
    keys.map(key => (
      <div key={key} className="flex flex-col pb-2">
        <span className="mb-1 text-p2 font-bold">{formatKey(key)}</span>
        <span className="text-p2">
          {renderValue(
            key as keyof InventoryWithAssets,
            data[key as keyof InventoryWithAssets],
            false,
            permissions,
          )}
        </span>
      </div>
    ));

  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <div className="shadow-md w-full rounded bg-white-100 p-4">
          <h2 className="mb-4 text-h2">Field Details</h2>
          {renderDetails(fieldDetails)}
        </div>
        <div className="shadow-md w-full rounded bg-white-100 p-4">
          <h2 className="mb-4 text-h2">Assets Details</h2>
          {renderDetails(importDetails)}
        </div>
        <div className="shadow-md w-full rounded bg-white-100 p-4">
          <h2 className="mb-4 text-h2">Contract Details</h2>
          {renderDetails(contractDetails)}
        </div>
      </div>
      <InventoryAdditionalDetails
        data={data.assetCalculations}
        permissions={permissions}
      />
    </>
  );
};

export default InventoryTimelineDetails;
