import { format } from "date-fns";
import { useLayoutEffect, useMemo, useRef, useState } from "react";

import { Icon } from "@ag/design-system/assets";
import { Button, Chip } from "@ag/design-system/atoms";

import { AuthResourceClass, useAuthPermissions } from "~features/permission";

import { HistoryActionType } from "../entities/inventory-history";
import { getHistoryActionTypeChip } from "../helpers/history-action-types";
import { renderValue } from "../helpers/history-values";
import {
  Difference,
  ExtendedCertificateGroupHistory,
} from "../helpers/parse-snapshots";

const TimelineItem: React.FC<{
  createdAt: string;
  action: string;
  differences: {
    [key: string]: Difference;
  };
  adminId?: string | null;
  isLatest?: boolean;
  permissions: { [key: string]: boolean };
}> = ({
  createdAt,
  action,
  differences,
  adminId,
  isLatest = false,
  permissions,
}) => {
  const { data: authPermissions } = useAuthPermissions();

  const hasAdminAccess = authPermissions?.read?.includes(
    AuthResourceClass.Admin,
  );

  const adjustToUTC = (date: Date) => {
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utcDate;
  };

  const zonedDate = adjustToUTC(new Date(createdAt));
  const day = format(zonedDate, "dd MMM yy HH:mm:ss");

  const [lineHeight, setLineHeight] = useState(0);
  const actionsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (actionsRef.current) {
      setLineHeight(actionsRef.current.clientHeight);
    }
  }, [differences]);

  const { label, variant } = getHistoryActionTypeChip(
    action as HistoryActionType,
  );

  return (
    <div className="relative mb-8 flex items-start">
      <div className="flex w-1/6 items-center gap-2">
        {isLatest && (
          <Chip variant="success" size="small">
            Latest
          </Chip>
        )}
        <span className="text-[18px] text-p1 font-medium text-grey-700">
          {day}
        </span>
      </div>
      <div className="relative flex w-1/12 justify-center">
        <div
          className="absolute w-0.5 bg-accent-200"
          style={{
            height: `${lineHeight}px`,
          }}
        />
        <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 transform rounded-full border-2 border-white-100 bg-accent-200 shadow-100" />
      </div>

      <div
        className="bg-white max-w-[550px] flex-1 space-y-4 rounded bg-white-100 px-4 py-4"
        ref={actionsRef}
      >
        <div className="flex items-center gap-2">
          <h3 className="text-h3">Action</h3>
          <Chip variant={variant}>{label}</Chip>
        </div>

        <ul className="text-gray-600 list-inside space-y-1.5">
          {Object.keys(differences).map((parsedKey, index) => {
            const { oldValue, newValue, originalKey } = differences[parsedKey];
            if (action === HistoryActionType.INSERT) {
              return (
                <li key={index} className="flex items-center gap-1.5">
                  <span className="text-p2 font-bold">{parsedKey}: </span>
                  <span className="text-p2">
                    {renderValue(originalKey, newValue, false, permissions)}
                  </span>
                </li>
              );
            } else if (action === HistoryActionType.DELETE) {
              return (
                <li
                  key={index}
                  className="flex items-center gap-1.5 line-through"
                >
                  <span className="text-p2 font-bold">{parsedKey}: </span>
                  <span className="text-p2">
                    {renderValue(originalKey, oldValue, true, permissions)}
                  </span>
                </li>
              );
            } else if (action === HistoryActionType.UPDATE) {
              return (
                <li key={index} className="flex items-center gap-1.5">
                  <span className="text-p2 font-bold">{parsedKey}: </span>
                  {oldValue !== "undefined" && oldValue !== "null" ? (
                    <>
                      <span className="text-p2">
                        {renderValue(originalKey, oldValue, true, permissions)}
                      </span>
                      <Icon
                        name="arrow-right"
                        className="text-p3 text-grey-700"
                      />
                      <span className="text-p2">
                        {renderValue(originalKey, newValue, false, permissions)}
                      </span>
                    </>
                  ) : (
                    <>
                      <Icon
                        name="arrow-right"
                        className="text-p3 text-grey-700"
                      />
                      <span className="text-p2">
                        {renderValue(originalKey, newValue, false, permissions)}
                      </span>
                    </>
                  )}
                </li>
              );
            }

            return null;
          })}
        </ul>
        {adminId ? (
          <>
            <span className="text-p2 text-grey-700">
              Performed by:{" "}
              {hasAdminAccess ? (
                <Button
                  href={`/admins/${adminId}`}
                  variant="text"
                  size="small"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Id {adminId}
                </Button>
              ) : (
                <span className="text-p2 text-grey-700">Id {adminId}</span>
              )}
            </span>
            <Icon name="user" className="pl-1 text-p3 text-accent-300" />
          </>
        ) : (
          <>
            <span className="text-p2 text-grey-700">
              Performed by:{" "}
              <span className="text-p2 text-accent-300">the system</span>
            </span>
            <Icon name="cog" className="pl-1 text-p3 text-accent-300" />
          </>
        )}
      </div>
    </div>
  );
};

const InventoryTimeline: React.FC<{
  timelineData: ExtendedCertificateGroupHistory[];
  sortOrder: "asc" | "desc";
  permissions: { [key: string]: boolean };
}> = ({ timelineData, sortOrder, permissions }) => {
  const latestItemIndex = useMemo(() => {
    if (sortOrder === "asc") {
      return timelineData.length - 1;
    }
    return 0;
  }, [timelineData, sortOrder]);

  return (
    <>
      {timelineData.map((item, index) => (
        <TimelineItem
          key={index}
          isLatest={index === latestItemIndex}
          permissions={permissions}
          {...item}
        />
      ))}
    </>
  );
};

export default InventoryTimeline;
