import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { Row } from "react-table";

import {
  BooleanCell,
  ChipCell,
  ChipCellValue,
  DateCell,
  LinkCell,
  LinkCellConfig,
  LinkCellValue,
  TextCell,
  TextCellValue,
} from "@ag/design-system/organisms";

import { TableSortingState, useTable } from "~components/table";
import {
  BuyoutContractStatus,
  getContractStatusChip as getBuyoutContractStatusChip,
} from "~features/buyout-agreements";
import {
  ResaleContractStatus,
  getContractStatusChip as getResaleContractStatusChip,
} from "~features/resale-agreements";
import { getCountryWithFlag } from "~helpers/countries";

import { ContractType, Inventory } from "../entities/inventory";
import {
  getCertificateGroupStatusChip,
  getContractUrl,
} from "../helpers/certificate-group-statuses";
import {
  getContractTypeChip,
  getContractTypeFromChipLabel,
} from "../helpers/contract-types";
import { getOwnershipTypeChip } from "../helpers/ownership-types";
import { formatDate } from "../helpers/parse-snapshots";

type TableData = {
  id: LinkCellConfig;
  importId: string;
  fieldId: LinkCellConfig;
  countryCode: string;
  cropTypeName: string;
  vintageYear: string | null;
  status: ChipCellValue;
  isoEligible: boolean | null;
  verraEligible: boolean | null;
  ownership: ChipCellValue | null;
  contractType: ChipCellValue | null;
  contractId: LinkCellConfig | null;
  contractStatus: ChipCellValue | null;
  contractUpdatedAt: string | null;
  companyVatNumber: string | null;
  userId: LinkCellConfig | null;
  createdAt: string;
};

export type ActionColumnProps = {
  row: Row<TableData>;
};

const columnHelper = createColumnHelper<TableData>();

export type UseInventoryTableParams = {
  data: Inventory[] | undefined;
  state: TableSortingState;
  hasFieldPermission: boolean;
  hasUserPermission: boolean;
  hasBuyoutPermission: boolean;
  hasResalePermission: boolean;
  onShowHistory: (id: string) => void;
};

export const useInventoryTable = ({
  data,
  state,
  hasFieldPermission,
  hasUserPermission,
  hasBuyoutPermission,
  hasResalePermission,
}: UseInventoryTableParams) => {
  const columns = useMemo(
    () =>
      getStickyColumns({
        hasFieldPermission,
        hasUserPermission,
        hasBuyoutPermission,
        hasResalePermission,
      }),
    [
      hasFieldPermission,
      hasUserPermission,
      hasBuyoutPermission,
      hasResalePermission,
    ],
  );
  const rowData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: state.sorting,
    },
    onSortingChange: state.setSorting,
  });
};

function getStickyColumns({
  hasFieldPermission,
  hasUserPermission,
  hasBuyoutPermission,
  hasResalePermission,
}: Pick<
  UseInventoryTableParams,
  | "hasFieldPermission"
  | "hasUserPermission"
  | "hasBuyoutPermission"
  | "hasResalePermission"
>) {
  return [
    columnHelper.accessor("id", {
      header: "ID",
      cell: LinkCell,
      enableSorting: true,
      size: 80,
    }),
    columnHelper.accessor("fieldId", {
      header: "Field ID",
      cell: hasFieldPermission ? LinkCell : TextCell,
      enableSorting: true,
      size: 120,
    }),
    columnHelper.accessor("importId", {
      header: "Import ID",
      cell: TextCell,
      enableSorting: false,
    }),
    columnHelper.accessor("countryCode", {
      header: "Country",
      cell: ({ getValue }) => {
        const country = getValue();
        if (!country) return null;
        const { flag, name } = getCountryWithFlag(country);
        return `${flag} ${name}`;
      },
    }),
    columnHelper.accessor("cropTypeName", {
      header: "Crop Type",
      cell: TextCell,
      enableSorting: false,
    }),
    columnHelper.accessor("vintageYear", {
      header: "Vintage Year",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("isoEligible", {
      header: "ISO Eligible",
      cell: BooleanCell,
    }),
    columnHelper.accessor("verraEligible", {
      header: "Verra Eligible",
      cell: BooleanCell,
    }),
    columnHelper.accessor("contractId", {
      header: "Contract ID",
      cell: props => {
        const value = props.getValue();
        if (!value) {
          return "-";
        }
        const contractType = getContractTypeFromChipLabel(
          props.row.original.contractType as { label: string },
        );

        if (
          (contractType === ContractType.Buyout && hasBuyoutPermission) ||
          (contractType === ContractType.Resale && hasResalePermission)
        ) {
          return (
            <LinkCell
              {...(props as unknown as CellContext<TableData, LinkCellValue>)}
            />
          );
        }

        return (
          <TextCell
            {...(props as unknown as CellContext<TableData, TextCellValue>)}
          />
        );
      },
      size: 120,
      enableSorting: true,
    }),
    columnHelper.accessor("ownership", {
      header: "Ownership",
      cell: ChipCell,
    }),
    columnHelper.accessor("contractType", {
      header: "Contract Type",
      cell: ChipCell,
      enableSorting: false,
    }),
    columnHelper.accessor("contractStatus", {
      header: "Contract Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("contractUpdatedAt", {
      header: "Contract Updated At",
      cell: props => {
        const value = props.getValue();
        if (!value) return "-";
        return formatDate(value);
      },
      enableSorting: true,
    }),
    columnHelper.accessor("companyVatNumber", {
      header: "VAT Number",
      cell: TextCell,
      enableSorting: false,
    }),
    columnHelper.accessor("userId", {
      header: "User ID",
      cell: hasUserPermission ? LinkCell : TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("createdAt", {
      header: "Created At",
      cell: DateCell,
      enableSorting: true,
    }),
  ];
}

function getRowData(inventoryList: Inventory[] | undefined): TableData[] {
  if (!inventoryList) return [];

  return inventoryList.map(inventory => ({
    id: {
      url: `/inventory-list/${inventory.id}`,
      title: inventory.id,
    },
    importId: inventory.importIds.join(", "),
    fieldId: {
      title: inventory.fieldId,
      url: `/carbon/fields/${inventory.fieldId}${
        inventory.vintageYear ? `?harvest-year=${inventory.vintageYear}` : ""
      }`,
    },
    countryCode: inventory.countryCode,
    cropTypeName: inventory.cropTypeName ?? "-",
    vintageYear: String(inventory.vintageYear),
    status: getCertificateGroupStatusChip(inventory.status),
    isoEligible: inventory.isoEligible,
    verraEligible: inventory.verraEligible,
    companyVatNumber: inventory.companyVatNumber,
    contractId: inventory.contractId
      ? {
          title: inventory.contractId,
          url:
            getContractUrl(inventory.contractId, inventory.contractType) || "/",
        }
      : null,
    ownership: inventory.ownership
      ? getOwnershipTypeChip(inventory.ownership)
      : null,
    contractType: inventory.contractType
      ? getContractTypeChip(inventory.contractType)
      : null,
    contractStatus:
      inventory.contractType === ContractType.Resale
        ? getResaleContractStatusChip(
            inventory.contractStatus as ResaleContractStatus,
          )
        : inventory.contractType === ContractType.Buyout
          ? getBuyoutContractStatusChip(
              inventory.contractStatus as BuyoutContractStatus,
            )
          : inventory.contractStatus,
    contractUpdatedAt: inventory.contractUpdatedAt,
    userId: inventory.userId
      ? {
          title: inventory.userId,
          url: `/users/${inventory.userId}`,
        }
      : null,
    createdAt: inventory.createdAt,
  }));
}
