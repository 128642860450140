import { z } from "zod";

import { BuyoutContractStatus } from "~features/buyout-agreements";
import { ResaleContractStatus } from "~features/resale-agreements";

export enum CertificateGroupStatus {
  IMPORTED = "imported",
  ALLOCATED = "allocated",
  ISSUED = "issued",
  SOLD = "sold",
}

export type InventoryCertificateGroupStatus =
  (typeof CertificateGroupStatus)[keyof typeof CertificateGroupStatus];

export enum ContractType {
  Buyout = "buyout",
  Resale = "resale",
}

export enum OwnershipType {
  Agreena = "agreena",
  Farmer = "farmer",
}

export enum CalculationStandard {
  Verra = "verra",
  Iso = "iso",
  SupplyChain = "supply_chain",
}

export enum EmissionType {
  Reduction = "reduction",
  Removal = "removal",
  Generic = "generic",
  Footprint = "footprint",
}

export enum CalculationQuantityType {
  Gross = "gross",
  Buffer = "buffer",
  Net = "net",
  FarmerNet = "farmer_net",
  Fee = "fee",
  PrmeiumPool = "premium_pool",
  Footprint = "footprint",
}

export const InventorySchema = z.object({
  id: z.string(),
  importIds: z.array(z.string()),
  status: z.nativeEnum(CertificateGroupStatus),
  fieldId: z.string(),
  countryCode: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  cropTypeId: z.string().nullable(),
  cropTypeName: z.string().nullable(),
  contractType: z.nativeEnum(ContractType).nullable(),
  contractId: z.string().nullable(),
  contractStatus: z
    .nativeEnum(BuyoutContractStatus)
    .or(z.nativeEnum(ResaleContractStatus))
    .nullable(),
  contractUpdatedAt: z.string().nullable(),
  companyVatNumber: z.string().nullable(),
  userId: z.string().nullable(),
  vintageYear: z.number().nullable(),
  harvestYear: z.number().nullable(),
  ownership: z.nativeEnum(OwnershipType).nullable(),
  isoEligible: z.boolean().nullable(),
  verraEligible: z.boolean().nullable(),
});
export type Inventory = z.infer<typeof InventorySchema>;

export const InventoryWithAssetsSchema = InventorySchema.extend({
  importIds: z.array(z.string()).nullable().optional(),
  assetCalculations: z.array(
    z.object({
      id: z.string(),
      isFinal: z.boolean(),
      standard: z.nativeEnum(CalculationStandard),
      emissionType: z.nativeEnum(EmissionType),
      creditLevel: z.string().nullable(),
      quantityType: z.nativeEnum(CalculationQuantityType),
      initialQuantity: z.string().nullable(),
      availableQuantity: z.string().nullable(),
      isEligible: z.boolean(),
      createdAt: z.string(),
    }),
  ),
});

export type InventoryWithAssets = z.infer<typeof InventoryWithAssetsSchema>;

export const InventoryFiltersSchema = z.object({
  importId: z.string().optional(),
  fieldId: z.string().optional(),
  status: z.nativeEnum(CertificateGroupStatus).or(z.literal("")).optional(),
  vintageYear: z.string().optional(),
  countryCode: z.string().optional(),
  isoEligible: z.string().optional(),
  verraEligible: z.string().optional(),
  contractType: z.nativeEnum(ContractType).or(z.literal("")).optional(),
  contractId: z.string().optional(),
  contractStatus: z
    .nativeEnum(BuyoutContractStatus)
    .or(z.nativeEnum(ResaleContractStatus))
    .or(z.literal(""))
    .optional(),
  companyVatNumber: z.string().optional(),
  userId: z.string().optional(),
});
export type InventoryFilters = z.infer<typeof InventoryFiltersSchema>;

export const InventoryTemplateDownloadFileUrl =
  "https://agreena-public-api-files.s3.eu-central-1.amazonaws.com/inventory_import_template.csv";
export const InventoryTemplateFileName = "inventory_import_template.csv";
