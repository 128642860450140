import type { KycQuestionnaireDetails as Props } from "../entities/sales-offer-details";

type ContractDetailsItemProps = {
  label: string;
  value?: string;
};

const ContractDetailsItem = ({ label, value }: ContractDetailsItemProps) => (
  <div className="grid-row-[auto,1fr] grid gap-1">
    <span className="text-p2 text-grey-800">{label}</span>
    <span className="rounded text-p2 text-grey-900">
      {value?.length ? value : "-"}
    </span>
  </div>
);

const KycContractDetails = (props: Props | null) => {
  if (!props) {
    return null;
  }

  const {
    companyName,
    registrationNumber,
    streetAddress,
    zipCode,
    city,
    countryName,
    contactName,
    iban,
    swift,
  } = props;

  return (
    <div
      className="mt-4 border-t border-grey-300 pt-4"
      data-testid="kyc-details"
    >
      <h3 className="text-h3">KYC Data</h3>

      <p className="mb-3 text-p2 text-grey-600">
        The following details will be used in the contract.
      </p>

      <div className="grid gap-4 md:grid-cols-3">
        <ContractDetailsItem label="Company name" value={companyName} />

        <ContractDetailsItem
          label="Registration number"
          value={registrationNumber}
        />

        <ContractDetailsItem label="Street address" value={streetAddress} />

        <ContractDetailsItem label="Zip code" value={zipCode} />

        <ContractDetailsItem label="City" value={city} />

        <ContractDetailsItem label="Country" value={countryName} />

        <ContractDetailsItem label="Contact Name" value={contactName} />

        <ContractDetailsItem label="IBAN" value={iban} />

        <ContractDetailsItem label="SWIFT" value={swift} />
      </div>
    </div>
  );
};

export default KycContractDetails;
