import { ChipVariant } from "@ag/design-system/atoms";

import {
  BuyoutContractStatus,
  getContractStatusChip,
} from "~features/buyout-agreements";
import { getCountryWithFlag } from "~helpers/countries";

import {
  CertificateGroupStatus,
  ContractType,
  OwnershipType,
} from "../entities/inventory";
import {
  CertificateGroupHistory,
  HistoryActionType,
} from "../entities/inventory-history";
import { getCertificateGroupStatusChip } from "./certificate-group-statuses";
import { getContractTypeChip } from "./contract-types";
import { getOwnershipTypeChip } from "./ownership-types";

export enum SnapshotKey {
  CONTRACT_UPDATED_AT = "contractUpdatedAt",
  UPDATE_AT = "updatedAt",
  CREATED_AT = "createdAt",
  COUNTRY_CODE = "countryCode",
  STATUS = "status",
  CONTRACT_TYPE = "contractType",
  CONTRACT_STATUS = "contractStatus",
  VERRA_ELIGIBLE = "verraEligible",
  ISO_ELIGIBLE = "isoEligible",
  OWNERSHIP = "ownership",
  CROP_TYPE_ID = "cropTypeId",
  CONTRACT_ID = "contractId",
  USER_ID = "userId",
  FIELD_ID = "fieldId",
  VINTAGE_YEAR = "vintageYear",
  HARVEST_YEAR = "harvestYear",
}

export type Difference = {
  oldValue?: unknown;
  newValue: unknown;
  originalKey: string;
};

type ExtractSnapshot = {
  [SnapshotKey.CONTRACT_UPDATED_AT]?: string;
  [SnapshotKey.UPDATE_AT]?: string;
  [SnapshotKey.CREATED_AT]?: string;
  [key: string]: unknown;
};

export const formatKey = (key: string): string => {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => str.toUpperCase())
    .replace(/\b\w/g, str => str.toUpperCase())
    .replace(/\bQuantity\b/g, "")
    .replace(/Code\b/g, "")
    .replace(/Name\b/g, "")
    .replace(/\bIso\b/g, "ISO")
    .replace(/\bId\b/g, "ID")
    .replace(/\bOwnership\b/g, "Net Ownership")
    .trim();
};

export const formatDate = (dateString: string): string => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return dateString;
  }

  const formatNumber = (num: number) => num.toString().padStart(2, "0");

  const day = formatNumber(date.getUTCDate());
  const month = formatNumber(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();
  const hours = formatNumber(date.getUTCHours());
  const minutes = formatNumber(date.getUTCMinutes());
  const seconds = formatNumber(date.getUTCSeconds());

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

const parseValue = (value: unknown): string => {
  if (value === null || value === undefined) {
    return "-";
  }
  return typeof value === "object" ? JSON.stringify(value) : String(value);
};

const addDifference = (
  differences: {
    [key: string]: Difference;
  },
  originalKey: string,
  parsedKey: string,
  oldValue: unknown,
  newValue: unknown,
) => {
  differences[parsedKey] = {
    oldValue,
    newValue,
    originalKey,
  };
};

export const findDifferences = (
  prevSnapshot: ExtractSnapshot,
  newSnapshot: ExtractSnapshot,
  action: HistoryActionType,
) => {
  const differences: {
    [key: string]: Difference;
  } = {};

  const keys = new Set([
    ...Object.keys(prevSnapshot),
    ...Object.keys(newSnapshot),
  ]);

  keys.forEach(key => {
    const prevValue = prevSnapshot[key];
    const newValue = newSnapshot[key];

    const parseKey = formatKey(key) || key;
    switch (key) {
      case SnapshotKey.CONTRACT_UPDATED_AT:
        {
          const prevDateString = prevValue;
          const newDateString = newValue;
          if (prevDateString !== newDateString) {
            addDifference(
              differences,
              key,
              parseKey,
              prevDateString,
              newDateString,
            );
          }
        }
        break;
      case SnapshotKey.CREATED_AT:
      case SnapshotKey.UPDATE_AT:
        break;
      default:
        switch (action) {
          case HistoryActionType.INSERT:
            if (newValue !== undefined && newValue !== null) {
              addDifference(
                differences,
                key,
                parseKey,
                "-",
                parseValue(newValue) ?? "-",
              );
            }
            break;
          case HistoryActionType.DELETE:
            if (prevValue !== undefined && prevValue !== null) {
              addDifference(
                differences,
                key,
                parseKey,
                parseValue(prevValue) ?? "-",
                "-",
              );
            }
            break;
          case HistoryActionType.UPDATE:
            if (prevValue === undefined) {
              if (newValue !== undefined && newValue !== null) {
                addDifference(
                  differences,
                  key,
                  parseKey,
                  "-",
                  parseValue(newValue) ?? "-",
                );
              }
            } else if (newValue === undefined) {
              addDifference(
                differences,
                key,
                parseKey,
                parseValue(prevValue) ?? "-",
                "-",
              );
            } else if (prevValue !== newValue) {
              addDifference(
                differences,
                key,
                parseKey,
                parseValue(prevValue) ?? "-",
                parseValue(newValue) ?? "-",
              );
            }
            break;
        }
    }
  });

  return differences;
};

export interface ExtendedCertificateGroupHistory
  extends CertificateGroupHistory {
  differences: {
    [key: string]: Difference;
  };
  snapshotParsed: ExtractSnapshot;
}

export const getFilteredItems = (timelineData: CertificateGroupHistory[]) => {
  if (!timelineData || !Array.isArray(timelineData)) {
    return { filteredItems: [], infoBoxData: {} };
  }

  const filteredItems = timelineData.reduce(
    (acc: ExtendedCertificateGroupHistory[], item: CertificateGroupHistory) => {
      const prevSnapshotParsed = JSON.parse(item.previousSnapshot);
      const snapshotParsed = JSON.parse(item.snapshot);
      const differences = findDifferences(
        prevSnapshotParsed,
        snapshotParsed,
        item.action,
      );

      if (
        !(
          item.action === HistoryActionType.UPDATE &&
          Object.keys(differences).length === 0
        )
      ) {
        acc.push({
          ...item,
          differences,
          snapshotParsed,
        });
      }
      return acc;
    },
    [] as ExtendedCertificateGroupHistory[],
  );

  return { filteredItems };
};

let contractType: ContractType | undefined;
let harvestYear: number | undefined;

export const formatValue = (
  key: string,
  value: unknown,
  permissions: { [key: string]: boolean },
): { formattedValue: string; variant?: ChipVariant; link?: string } => {
  if (key === SnapshotKey.CONTRACT_TYPE) {
    contractType = value as ContractType;
  }
  if (key === SnapshotKey.HARVEST_YEAR) {
    harvestYear = value as number;
  }

  const keyToUrlMap: { [key in SnapshotKey]?: string } = {
    [SnapshotKey.CROP_TYPE_ID]: value
      ? `/carbon/crop-types/${value}/edit`
      : undefined,
    [SnapshotKey.CONTRACT_ID]: value
      ? contractType === ContractType.Resale
        ? `/carbon/agreements/resale/${value}`
        : contractType === ContractType.Buyout
          ? `/carbon/agreements/buyout/${value}`
          : undefined
      : undefined,
    [SnapshotKey.USER_ID]: value ? `/users/${value}` : undefined,
    [SnapshotKey.FIELD_ID]: value
      ? `/carbon/fields/${value}${
          harvestYear ? `?harvest-year=${harvestYear}` : ""
        }`
      : undefined,
  };

  switch (key) {
    case SnapshotKey.COUNTRY_CODE: {
      const { flag, name } = getCountryWithFlag(value as string);
      return { formattedValue: `${flag} ${name}` };
    }
    case SnapshotKey.STATUS: {
      const statusChip = getCertificateGroupStatusChip(
        value as CertificateGroupStatus,
      );
      return {
        formattedValue: statusChip.label,
        variant: statusChip.variant,
      };
    }
    case SnapshotKey.CONTRACT_TYPE: {
      const contractTypeChip = getContractTypeChip(value as ContractType);
      return {
        formattedValue: contractTypeChip.label,
        variant: contractTypeChip.variant,
      };
    }
    case SnapshotKey.CONTRACT_STATUS: {
      const contractStatusChip = getContractStatusChip(
        value as BuyoutContractStatus,
      );
      return {
        formattedValue: contractStatusChip.label,
        variant: contractStatusChip.variant,
      };
    }
    case SnapshotKey.VERRA_ELIGIBLE:
    case SnapshotKey.ISO_ELIGIBLE: {
      // since I'm parsing everything above, the values are gonna be strings
      const isEligible =
        typeof value === "string" ? value === "true" : value === true;

      const variant = isEligible ? "success" : "danger";
      const label = isEligible ? "Yes" : "No";
      return { formattedValue: label, variant };
    }
    case SnapshotKey.OWNERSHIP: {
      const ownershipChip = getOwnershipTypeChip(value as OwnershipType);
      return {
        formattedValue: ownershipChip.label,
        variant: ownershipChip.variant,
      };
    }
    case SnapshotKey.CONTRACT_UPDATED_AT: {
      const formattedDate = formatDate(value as string);
      return { formattedValue: formattedDate };
    }
    case SnapshotKey.CROP_TYPE_ID:
    case SnapshotKey.CONTRACT_ID:
    case SnapshotKey.USER_ID:
    case SnapshotKey.FIELD_ID: {
      const url = keyToUrlMap[key as SnapshotKey];
      const hasPermission = permissions[key];
      return {
        formattedValue: String(value),
        link: hasPermission ? url : undefined,
      };
    }
    default:
      return {
        formattedValue:
          typeof value === "object" ? JSON.stringify(value) : String(value),
      };
  }
};
