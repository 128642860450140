import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Toggle } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";

type OwnProps = {
  label: string;
  tooltip?: string;
  type?: "hidden";
  error?: FieldError;
  isDisabled?: boolean;
  description?: string;
  isDescriptionIconVisible?: boolean;
};

type Props = React.InputHTMLAttributes<HTMLInputElement> & OwnProps;

export const ToggleField = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      type,
      tooltip,
      error,
      isDisabled = false,
      description,
      isDescriptionIconVisible = true,
      ...rest
    },
    ref,
  ) => (
    <FormField.Root type={type === "hidden" ? "hidden" : undefined}>
      <FormField.InlineLabel tooltip={tooltip}>
        <Toggle
          {...rest}
          ref={ref}
          disabled={isDisabled}
          isInvalid={Boolean(error)}
        />

        {label}
      </FormField.InlineLabel>

      {error && <FormField.Error>{error.message}</FormField.Error>}

      {!error && description && (
        <FormField.Description isIconVisible={isDescriptionIconVisible}>
          {description}
        </FormField.Description>
      )}
    </FormField.Root>
  ),
);

ToggleField.displayName = "ToggleField";
