import {
  Column,
  DeepKeys,
  RowData,
  Table as TTable,
} from "@tanstack/react-table";
import { useCallback } from "react";

import { Icon } from "@ag/design-system/assets";
import { Button, Checkbox } from "@ag/design-system/atoms";
import {
  Drawer,
  TableState,
  getGroupedState,
} from "@ag/design-system/organisms";
import { cluster } from "@ag/design-system/utils";
import I18n from "@ag/i18n";

import * as styles from "./table-settings.css";

/* -------------------------------------------------------------------------------------------------
 * TableSettings
 * -----------------------------------------------------------------------------------------------*/
export type TableSettingsProps<TData extends RowData> = {
  instance: TTable<TData>;
  state: TableState<TData>;
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
  onStateChange: (state: TableState<TData>) => void;
};

export function TableSettings<TData extends RowData>({
  instance,
  state = {},
  isOpen = false,
  onClose,
  onReset,
  onStateChange,
}: TableSettingsProps<TData>) {
  const columns = instance.getAllFlatColumns().filter(col => col.accessorFn);
  const isGroupingEnabled = Boolean(instance.options.enableGrouping);

  const toggleVisibilty = useCallback(
    (column: Column<TData, unknown>) => {
      const isVisible = column.getIsVisible();
      const groupedState = getGroupedState(column, isGroupingEnabled);

      const columnVisibility = state.columnVisibility ?? {};
      columnVisibility[column.id] = !isVisible;

      // Make sure to hide/show all sub columns of a group too.
      groupedState.columns.slice(1).forEach(subColumn => {
        columnVisibility[subColumn.id] = !isVisible;
      });

      onStateChange({
        ...state,
        columnVisibility,
      });
    },
    [state, onStateChange, isGroupingEnabled],
  );

  const togglePinned = useCallback(
    (column: Column<TData, unknown>) => {
      const isColumnPinned = column.getIsPinned();

      let left = state.columnPinning?.left ?? [];

      // we only deal with "left" pinned, as "right" is rare, and shouldn't be touched when used.
      if (isColumnPinned === "left") {
        left = left.filter(rightColumn => rightColumn !== column.id);
      } else {
        left.push(column.id as DeepKeys<TData>);
      }

      onStateChange({
        ...state,
        columnPinning: {
          ...(state.columnPinning || {}),
          left,
        },
      });
    },
    [state, onStateChange],
  );

  const toggleSize = useCallback(
    (column: Column<TData, unknown>) => {
      const { size } = column.columnDef;
      const isExpanded = column.getSize() === 0;

      onStateChange({
        ...state,
        columnSizing: {
          ...(state.columnSizing || {}),
          [column.id]: isExpanded ? size ?? 0 : 0,
        },
      });
    },
    [state, onStateChange],
  );

  return (
    <Drawer.Root side="right" isOpen={isOpen} onClose={onClose}>
      <Drawer.Header>{I18n.t("js.admin.table_settings.title")}</Drawer.Header>

      <Drawer.Content>
        <div className={styles.root()}>
          <section>
            <h2 className={styles.sectionHeader}>
              {I18n.t("js.admin.table_settings.columns")}
            </h2>
            {columns.map(column => {
              const { size } = column.columnDef;

              const groupedColumns = column.parent?.columns ?? [];
              const isGrouped = Boolean(groupedColumns.length);
              const isNested = isGrouped && groupedColumns[0] !== column;

              if (isNested) return null;

              const isVisible = column.getIsVisible();
              const isColumnPinned = column.getIsPinned();

              const canPin = Boolean(
                column.getCanPin() &&
                  !isGrouped &&
                  column.getIsPinned() !== "right",
              );

              const canExpand = Boolean(column.getCanResize() && size);

              const isExpanded = !column.getSize();

              return (
                <div key={column.id} className={styles.item}>
                  <label className={styles.label}>
                    <Checkbox
                      name={column.id}
                      checked={isVisible}
                      onChange={() => toggleVisibilty(column)}
                    />

                    {typeof column.columnDef.header === "string"
                      ? column.columnDef.header
                      : column.id}
                  </label>

                  {canPin || canExpand ? (
                    <div className={cluster({ gap: 0 })}>
                      {canPin ? (
                        <button
                          className={styles.pinnedToggle}
                          disabled={!isVisible || !canPin || isExpanded}
                          onClick={() => togglePinned(column)}
                        >
                          <Icon name={isColumnPinned ? "unlock" : "lock"} />
                        </button>
                      ) : null}

                      {canExpand ? (
                        <button
                          className={styles.sizeToggle}
                          disabled={
                            !isVisible || !canExpand || Boolean(isColumnPinned)
                          }
                          onClick={() => toggleSize(column)}
                        >
                          <Icon
                            name={
                              isExpanded ? "compress-arrows" : "expand-arrows"
                            }
                          />
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </section>
        </div>
      </Drawer.Content>

      <Drawer.Footer>
        <Button variant="secondary" onClick={onReset}>
          {I18n.t("js.admin.table_settings.reset")}
        </Button>
      </Drawer.Footer>
    </Drawer.Root>
  );
}
