import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField, ToggleField } from "@ag/form-fields";

import { Filters, transformInitialFilterValues } from "~components/filters";
import { CertificateGroupStatus } from "~features/inventory/entities/inventory";
import { renderEnumOptionsLabel } from "~helpers";
import { trimAndDispatchInput } from "~helpers/input";

import {
  EstimationType,
  OwnershipType,
  PortfolioFilters,
  PortfolioFiltersSchema,
} from "../entities/inventory-portfolio";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  query: object;
  onClear: () => void;
  onSubmit: (data: PortfolioFilters) => void;
};

const PortfolioTableFilters = ({
  isOpen,
  onClose,
  query,
  onClear,
  onSubmit,
}: Props) => {
  const [isVerraRiskBufferEnabled, setisVerraRiskBufferEnabled] = useState<
    undefined | "true"
  >(undefined);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PortfolioFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(PortfolioFiltersSchema),
  });

  const handleFormFiltersClear = () => {
    reset();
    setisVerraRiskBufferEnabled(undefined);
    onClear();
  };

  return (
    <Filters.Drawer
      isOpen={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      onClear={handleFormFiltersClear}
    >
      <Controller
        name="vintageYear"
        control={control}
        render={({ field }) => (
          <InputField
            label="Vintage Year"
            description='Enter a year "2022" or a comma-separated years "2022,2023"'
            {...field}
            onChange={e => trimAndDispatchInput(e.target.value, field.onChange)}
            error={errors.vintageYear}
          />
        )}
      />

      <Controller
        name="ownership"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Ownership"
            optionsClassName="z-modal"
          >
            <Select.OptionAll>All</Select.OptionAll>
            {renderEnumOptionsLabel(OwnershipType)}
          </SelectField>
        )}
      />

      <Controller
        name="availability"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Availability"
            optionsClassName="z-modal"
          >
            <Select.OptionAll>All</Select.OptionAll>
            {renderEnumOptionsLabel(CertificateGroupStatus, [
              "allocated",
              "sold",
            ])}
          </SelectField>
        )}
      />

      <Controller
        name="estimation"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Estimation"
            optionsClassName="z-modal"
          >
            <Select.OptionAll>All</Select.OptionAll>
            {renderEnumOptionsLabel(EstimationType)}
          </SelectField>
        )}
      />

      <div className="mt-4">
        <Controller
          name="verraBufferEnabled"
          control={control}
          render={({ field, fieldState }) => (
            <ToggleField
              id="verraRiskBufferToggle"
              label="Enable 50% Verra Risk Buffer"
              description="This buffer is used to prevent overselling more Verra certificates"
              checked={Boolean(isVerraRiskBufferEnabled)}
              onChange={e => {
                const isChecked = e.target.checked;
                setisVerraRiskBufferEnabled(isChecked ? "true" : undefined);
                field.onChange(isChecked ? "true" : undefined);
              }}
              error={fieldState.error}
            />
          )}
        />
      </div>
    </Filters.Drawer>
  );
};

export default PortfolioTableFilters;
