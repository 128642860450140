import { Button, Chip } from "@ag/design-system/atoms";

import { formatValue } from "./parse-snapshots";

export const renderValue = (
  originalKey: string,
  value: unknown,
  isStrikethrough: boolean,
  permissions: { [key: string]: boolean },
) => {
  if (value === null || value === undefined) {
    return "-";
  }
  const { formattedValue, variant, link } = formatValue(
    originalKey,
    value,
    permissions,
  );

  const valueContent = variant ? (
    <Chip variant={variant} className={isStrikethrough ? "line-through" : ""}>
      {formattedValue}
    </Chip>
  ) : link ? (
    <Button
      variant="text"
      size="small"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={isStrikethrough ? "line-through" : ""}>
        {formattedValue}
      </span>
    </Button>
  ) : (
    <span className={isStrikethrough ? "line-through" : ""}>
      {formattedValue ?? "-"}
    </span>
  );

  return valueContent;
};
