import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ActionsCell,
  ActionsCellValue,
  ChipCell,
  ChipCellValue,
  DateCell,
  DateCellConfig,
  LinkCell,
  LinkCellValue,
  TableRowError,
  TextCell,
  TextCellValue,
} from "@ag/design-system/organisms";

import { useList } from "~components/list";
import { TableSortingState } from "~components/table";
import { downloadFile } from "~features/inventory/helpers/download-file";

import { ImportStatus, InventoryImport } from "../entities/inventory-import";
import { getStatusChip } from "../helpers/import-statuses";

type ListData = {
  id: string;
  adminId?: LinkCellValue | TextCellValue;
  filename: string | null | undefined;
  status: ChipCellValue;
  createdAt: DateCellConfig | null;
  processedAt: DateCellConfig | null;
  startedProcessingAt: DateCellConfig | null;
  actions: ActionsCellValue;

  import: InventoryImport;
};

export const useActiveInventoryImportsTable = (
  data: InventoryImport[] | undefined,
  state: TableSortingState,
  hasAdminAccess: boolean,
  isCurrentAdmin: (adminId: string) => boolean,
  handleErrorButtonClick: (
    params: { id: string; errors: string[] },
    canDiscardImport: boolean,
  ) => void,
  handleCancelImportButtonClick: (id: string) => void,
) => {
  const columns = useMemo(() => getColumns(hasAdminAccess), [hasAdminAccess]);
  const rowData = useMemo(
    () =>
      getRowData(
        data,
        hasAdminAccess,
        isCurrentAdmin,
        handleErrorButtonClick,
        handleCancelImportButtonClick,
      ),
    [
      data,
      hasAdminAccess,
      isCurrentAdmin,
      handleErrorButtonClick,
      handleCancelImportButtonClick,
    ],
  );

  const errors = data?.reduce(
    (acc, item) => {
      if (item.errors.length > 0) {
        acc[item.id] = item.errors.map(error => new TableRowError(error));
      }
      return acc;
    },
    {} as Record<string, TableRowError<ListData>[]>,
  );

  return useList<ListData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    meta: {
      errors,
    },
    initialState: {
      columnVisibility: {
        admin: hasAdminAccess ?? false,
      },
    },
    state,
    onSortingChange: state.setSorting,
  });

  function getColumns(hasAdminAccess: boolean) {
    const columnHelper = createColumnHelper<ListData>();

    return [
      columnHelper.accessor("id", {
        header: "Import ID",
      }),
      columnHelper.accessor("adminId", {
        header: "Admin ID",
        cell: hasAdminAccess ? LinkCell : TextCell,
      }),
      columnHelper.accessor("filename", {
        header: "File name",
        maxSize: 200,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ChipCell,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created",
        cell: DateCell,
        enableSorting: true,
      }),
      columnHelper.accessor("startedProcessingAt", {
        header: "Started processing",
        cell: DateCell,
      }),
      columnHelper.accessor("processedAt", {
        header: "Processed",
        cell: DateCell,
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        cell: ActionsCell,
      }),
    ];
  }

  function getRowData(
    data: InventoryImport[] | undefined,
    hasAdminAccess: boolean,
    isCurrentAdmin: (adminId: string) => boolean,
    handleErrorButtonClick: (
      params: { id: string; errors: string[] },
      isTheSameAdmin: boolean,
    ) => void,
    handleCancelImportButtonClick: (id: string) => void,
  ): ListData[] {
    if (!data) return [];

    return data.map(importData => {
      const hasErrors =
        Array.isArray(importData.errors) && importData.errors.length > 0;

      const cancellable =
        importData.status === ImportStatus.FAILED ||
        importData.status === ImportStatus.UPLOADING;

      return {
        id: importData.id,
        filename: importData.filename,
        status: importData.status ? getStatusChip(importData.status) : null,
        adminId: hasAdminAccess
          ? {
              title: importData.adminId,
              url: `/admins/${importData.adminId}`,
            }
          : importData.adminId,
        createdAt: { value: importData.createdAt, format: "datetime" },
        processedAt: { value: importData.processedAt, format: "datetime" },
        startedProcessingAt: {
          value: importData.startedProcessingAt,
          format: "datetime",
        },
        actions: {
          title: "Actions",
          items: [
            {
              children: "Download file",
              isDisabled: !importData.fileDownloadUrl,
              onClick: () =>
                downloadFile(
                  `/file?${new URLSearchParams({
                    url: importData.fileDownloadUrl || "",
                    apiSource: "node-markets",
                  })}`,
                  importData.filename || "file.csv",
                ),
            },
            {
              children: "Cancel import",
              isDanger: true,
              isDisabled: !cancellable || !isCurrentAdmin(importData.adminId),
              onClick: () => handleCancelImportButtonClick(importData.id),
            },
            ...(hasErrors
              ? [
                  {
                    children: "View errors",
                    onClick: () =>
                      handleErrorButtonClick(
                        {
                          id: importData.id,
                          errors: importData.errors,
                        },
                        isCurrentAdmin(importData.adminId),
                      ),
                  },
                ]
              : []),
          ],
        },
        import: importData,
      };
    });
  }
};
