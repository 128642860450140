/**
 * Trims spaces around separators (-, ;, ,) and from the ends of the input.
 *
 * @param {string} input - The input string to be trimmed.
 * @param {function} onChange - The function to call with the trimmed input.
 * @param {boolean} [allowNegative=false] - Whether to allow negative numbers.

 */
export const trimAndDispatchInput = (
  input: string,
  onChange: (value: string) => void,
  allowNegativeNumbers: boolean = false,
) => {
  let trimmedInput = input.replace(/\s*([-,;])\s*/g, "$1").trim();
  if (!allowNegativeNumbers) {
    trimmedInput = trimmedInput.replace(/(^|[^\d])-+\d+/g, (match, p1) =>
      p1 === "" ? "" : p1 + "0",
    );
  }
  onChange(trimmedInput);
};
