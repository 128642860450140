import { z } from "zod";

import { AgreementType } from "../types/agreement-type";
import { OfferStatus } from "../types/offer-status";

export enum SalesOfferStatus {
  Signed = "signed",
  Sent = "sent",
  Withdrawn = "withdrawn",
}

export enum KYCStatus {
  Pending = "pending",
  Approved = "approved",
}

export enum Contacted {
  YES = "yes",
  NO = "no",
}

export const OfferDetailsSchema = z.object({
  totalPayableAmount: z.string(),
  totalPurchaseAmount: z.string(),
  amountWitheldForPremiumPool: z.string(),
  pricePerRemovalCertificate: z.string(),
  pricePerReductionCertificate: z.string(),
  estimatedRemovalCertificates: z.string(),
  estimatedReductionCertificates: z.string(),
  estimatedIsoRemovalCertificates: z.string().optional(),
  estimatedIsoReductionCertificates: z.string().optional(),
  estimatedVerraRemovalCertificates: z.string().optional(),
  estimatedVerraReductionCertificates: z.string().optional(),
  brokerPercentageFee: z.string(),
  notes: z.string().optional(),
  suggestedContractType: z.array(z.nativeEnum(AgreementType)),
  allowedContractType: z.array(z.nativeEnum(AgreementType)),
  contractType: z.nativeEnum(AgreementType).nullable(),
  useKycRegistrationNumberAsContractVat: z.boolean().optional(),
});

export const OfferSignerDetailsSchema = z.object({
  name: z.string(),
  title: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  verified: z.boolean(),
});

export const OfferKycQuestionnaireDataSchema = z
  .object({
    companyName: z.string(),
    registrationNumber: z.string(),
    streetAddress: z.string(),
    zipCode: z.string(),
    city: z.string(),
    countryName: z.string(),
    contactName: z.string(),
    iban: z.string(),
    swift: z.string(),
  })
  .partial()
  .nullable();

export type OfferDetails = z.infer<typeof OfferDetailsSchema>;

export type OfferSignerDetails = z.infer<typeof OfferSignerDetailsSchema>;

export type OfferKycQuestionnaireData = z.infer<
  typeof OfferKycQuestionnaireDataSchema
>;

export const SalesOfferSchema = z.object({
  userId: z.string(),
  id: z.string(),
  carbonUserId: z.string(),
  fieldsTotalSizeHa: z.string(),
  companyVatNumber: z.string(),
  harvestYear: z.number(),
  accepted: z.boolean(),
  contacted: z.boolean(),
  userName: z.string().optional(),
  country: z.string().optional(),
  status: z.nativeEnum(OfferStatus),
  createdContractId: z.string().nullable(),
  kycStatus: z.nativeEnum(KYCStatus),
  kycValid: z.boolean(),
  offerDetails: OfferDetailsSchema,
  signerDetails: OfferSignerDetailsSchema,
  kycQuestionnaireData: OfferKycQuestionnaireDataSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type SalesOffer = z.infer<typeof SalesOfferSchema>;

export const OfferLastModifiedSchema = z
  .object({
    by: z.object({
      id: z.string(),
      email: z.string(),
    }),
    at: z.string(),
  })
  .nullable();

export type OfferLastModified = z.infer<typeof OfferLastModifiedSchema>;
