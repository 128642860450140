import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useMemo } from "react";

import { useTable } from "~components/table";
import { getCountryWithFlag } from "~helpers/countries";

import { Portfolio } from "../entities/inventory-portfolio";

type TableData = {
  country: string;
  isoEligibleOnly: {
    isoReductions: number;
    isoRemovals: number;
  };
  verraEligibleOnly: {
    verraReductions: number;
    verraRemovals: number;
  };
  verraAndIsoEligible: {
    isoReductions: number;
    isoRemovals: number;
    verraReductions: number;
    verraRemovals: number;
  };
};

const columnHelper = createColumnHelper<TableData>();

type GenerateCellColumnProps = {
  header: string;
  accessorKey:
    | keyof TableData
    | "isoEligibleOnly.isoReductions"
    | "isoEligibleOnly.isoRemovals"
    | "verraEligibleOnly.verraReductions"
    | "verraEligibleOnly.verraRemovals"
    | "verraAndIsoEligible.isoReductions"
    | "verraAndIsoEligible.isoRemovals"
    | "verraAndIsoEligible.verraReductions"
    | "verraAndIsoEligible.verraRemovals";
};

const generateCellColumn = ({
  header,
  accessorKey,
}: GenerateCellColumnProps) => ({
  header,
  accessorKey,
  meta: {
    cellBackground: (_: unknown, row: { country: string }) =>
      row.country === "Total" ? "green" : undefined,
  },
  cell: (props: CellContext<TableData, number>) => {
    const value = props.getValue();
    return value;
  },
});

const generateSplittedCellColumn = (
  header: string,
  accessorKey:
    | keyof TableData
    | "verraEligibleOnly.verraReductions"
    | "verraEligibleOnly.verraRemovals"
    | "verraAndIsoEligible.verraReductions"
    | "verraAndIsoEligible.verraRemovals",
  isVerraRiskBufferEnabled: boolean,
) => ({
  header,
  accessorKey,
  cell: (props: CellContext<TableData, number>) => {
    const value = props.getValue();

    return (
      <div className="flex items-center">
        <span className={`${isVerraRiskBufferEnabled ? "text-red-500" : ""}`}>
          {value}
        </span>
      </div>
    );
  },

  meta: {
    cellBackground: (_: unknown, row: { country: string }) =>
      row.country === "Total" ? "green" : undefined,
  },
});

export const usePortfolioTable = ({
  data,
  isVerraRiskBufferEnabled,
}: {
  data: Portfolio | undefined;
  isVerraRiskBufferEnabled: boolean;
}) => {
  const rowData = useMemo(() => {
    const rows = data ? getRowData(data) : [];
    const totalRow = data ? getTotalRow(data) : null;
    return totalRow ? rows.concat(totalRow) : rows;
  }, [data]);

  const columns = useMemo(
    () => getColumns(isVerraRiskBufferEnabled),
    [isVerraRiskBufferEnabled],
  );

  return useTable<TableData>({
    data: rowData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    groupedColumnMode: false,
    enableGrouping: false,
  });
};

function getRowData(portfolio: Portfolio): TableData[] {
  if (!portfolio?.countries) return [];

  const { countries } = portfolio;

  return Object.keys(countries).map(country => {
    return {
      country,
      isoEligibleOnly: {
        isoReductions: Number(
          countries[country].isoEligibleOnly?.isoReductions,
        ),
        isoRemovals: Number(countries[country].isoEligibleOnly?.isoRemovals),
      },
      verraEligibleOnly: {
        verraReductions: Number(
          countries[country].verraEligibleOnly?.verraReductions,
        ),
        verraRemovals: Number(
          countries[country].verraEligibleOnly?.verraRemovals,
        ),
      },
      verraAndIsoEligible: {
        isoReductions: Number(
          countries[country].verraAndIsoEligible?.isoReductions,
        ),
        isoRemovals: Number(
          countries[country].verraAndIsoEligible?.isoRemovals,
        ),
        verraReductions: Number(
          countries[country].verraAndIsoEligible?.verraReductions,
        ),
        verraRemovals: Number(
          countries[country].verraAndIsoEligible?.verraRemovals,
        ),
      },
    };
  });
}

function getTotalRow(portfolio: Portfolio) {
  const { total } = portfolio;

  if (
    Object.keys(total.isoEligibleOnly).length === 0 ||
    Object.keys(total.verraEligibleOnly).length === 0 ||
    Object.keys(total.verraAndIsoEligible).length === 0
  ) {
    return null;
  }

  const isoReductions = Number(total.isoEligibleOnly.isoReductions);
  const isoRemovals = Number(total.isoEligibleOnly.isoRemovals);
  const verraReductions = Number(total.verraEligibleOnly.verraReductions);
  const verraRemovals = Number(total.verraEligibleOnly.verraRemovals);
  const verraAndIsoReductions = Number(total.verraAndIsoEligible.isoReductions);
  const verraAndIsoRemovals = Number(total.verraAndIsoEligible.isoRemovals);
  const verraAndIsoVerraReductions = Number(
    total.verraAndIsoEligible.verraReductions,
  );
  const verraAndIsoVerraRemovals = Number(
    total.verraAndIsoEligible.verraRemovals,
  );

  const isEmpty = [
    isoReductions,
    isoRemovals,
    verraReductions,
    verraRemovals,
    verraAndIsoReductions,
    verraAndIsoRemovals,
    verraAndIsoVerraReductions,
    verraAndIsoVerraRemovals,
  ].every(value => value === 0);

  if (isEmpty) {
    return null;
  }

  return {
    country: "Total",
    isoEligibleOnly: {
      isoReductions,
      isoRemovals,
    },
    verraEligibleOnly: {
      verraReductions,
      verraRemovals,
    },
    verraAndIsoEligible: {
      isoReductions: verraAndIsoReductions,
      isoRemovals: verraAndIsoRemovals,
      verraReductions: verraAndIsoVerraReductions,
      verraRemovals: verraAndIsoVerraRemovals,
    },
  };
}

function getColumns(isVerraRiskBufferEnabled: boolean) {
  return [
    columnHelper.accessor("country", {
      header: "Country",
      cell: ({ getValue }) => {
        const country = getValue();
        if (country === "Total") return <strong>Total</strong>;
        if (!country) return null;
        const { flag, name } = getCountryWithFlag(country);
        return `${flag} ${name}`;
      },
      meta: {
        cellBackground: (_, row) =>
          row.country === "Total" ? "green" : undefined,
      },
    }),
    columnHelper.group({
      id: "isoEligible",
      header: "ISO Eligible",
      columns: [
        generateCellColumn({
          header: "ISO Reduction",
          accessorKey: "isoEligibleOnly.isoReductions",
        }),
        generateCellColumn({
          header: "ISO Removal",
          accessorKey: "isoEligibleOnly.isoRemovals",
        }),
      ],
    }),
    columnHelper.group({
      id: "verraEligible",
      header: "Verra Eligible",
      columns: [
        generateSplittedCellColumn(
          "Verra Reduction",
          "verraEligibleOnly.verraReductions",
          isVerraRiskBufferEnabled,
        ),
        generateSplittedCellColumn(
          "Verra Removal",
          "verraEligibleOnly.verraRemovals",
          isVerraRiskBufferEnabled,
        ),
      ],
    }),
    columnHelper.group({
      id: "verraAndIsoEligible",
      header: "ISO & Verra Eligible",
      columns: [
        generateCellColumn({
          header: "ISO Reduction",
          accessorKey: "verraAndIsoEligible.isoReductions",
        }),
        generateCellColumn({
          header: "ISO Removal",
          accessorKey: "verraAndIsoEligible.isoRemovals",
        }),
        generateSplittedCellColumn(
          "Verra Reduction",
          "verraAndIsoEligible.verraReductions",
          isVerraRiskBufferEnabled,
        ),
        generateSplittedCellColumn(
          "Verra Removal",
          "verraAndIsoEligible.verraRemovals",
          isVerraRiskBufferEnabled,
        ),
      ],
    }),
  ];
}
