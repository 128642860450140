import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  CertificateGroupHistory,
  CertificateGroupHistoryResponse,
  CertificateGroupHistorySchema,
} from "../entities/inventory-history";

type CertificateGroupHistoryParams = MetaArgs & {
  sort?: {
    createdAt?: SortingDirection;
  };
};

type UseHistoryQueryOptions = {
  enabled?: boolean;
  onSuccess?: (
    contractsResult: InfiniteData<{
      items: CertificateGroupHistory[];
      meta: CertificateGroupHistoryResponse["meta"];
    }>,
  ) => void;
};

export const generateCertificateGroupHistoryQueryKey = (keyVariables?: {
  id: string;
  params?: CertificateGroupHistoryParams | undefined;
}) => ["certificate-group-history", keyVariables].filter(Boolean);

const getCertificateGroupHistory = async (
  id: string,
  params: CertificateGroupHistoryParams | undefined,
) => {
  const response = await axios.get<
    CommonPaginatedResponse<CertificateGroupHistory>
  >(`/api/v1/inventory/certificate-groups/${id}/histories`, {
    params: {
      ...params,
      limit: 100,
      page: params?.page,
    },
    schema: createPaginatedDataSchema(CertificateGroupHistorySchema),
    apiSource: "node-markets",
  });

  return response.data.success!.data;
};

export const useCertificateGroupHistoryInfinityQuery = (
  id: string,
  params?: CertificateGroupHistoryParams,
  options?: UseHistoryQueryOptions,
) =>
  useInfiniteQuery(
    generateCertificateGroupHistoryQueryKey({ id, params }),
    ({ pageParam = 1 }) =>
      getCertificateGroupHistory(id, { ...params, page: pageParam }),
    {
      getNextPageParam: lastPage => {
        const { currentPage, totalPages } = lastPage.meta;
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      ...options,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

export const useCertificateGroupHistoryQuery = (
  id: string,
  params?: CertificateGroupHistoryParams,
) =>
  useQuery(
    generateCertificateGroupHistoryQueryKey({ id, params }),
    () => getCertificateGroupHistory(id, params),
    {
      keepPreviousData: true,
    },
  );
