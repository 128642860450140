import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useMemo } from "react";

import { Chip, ChipVariant } from "@ag/design-system/atoms";
import {
  BooleanCellValue,
  ChipCell,
  ChipCellValue,
  DateCell,
} from "@ag/design-system/organisms";

import { useTable } from "~components/table";

import {
  CalculationQuantityType,
  CalculationStandard,
  EmissionType,
  InventoryWithAssets,
} from "../entities/inventory";

type TableData = {
  id: string;
  availableQuantity: string;
  isFinal: BooleanCellValue;
  standard: ChipCellValue;
  emissionType: ChipCellValue;
  initialQuantity: string | null;
  creditLevel: string | null;
  quantityType: ChipCellValue;
  createdAt: string;
};

export const useHistoryAssetsTable = (
  data: InventoryWithAssets["assetCalculations"],
) => {
  const columns = useMemo(() => getColumns(), []);
  const rowData = useMemo(() => getRowData(data), [data]);
  return useTable<TableData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
  });

  function getColumns() {
    const columnHelper = createColumnHelper<TableData>();

    const tooltipProperties = {
      final: {
        text: "Final",
        variant: "success",
        iconColor: "text-accent-300",
      },
      estimated: {
        text: "Estimated",
        variant: "warning",
        iconColor: "text-messaging-warning-700",
      },
    };

    return [
      columnHelper.accessor("isFinal", {
        header: "State",
        cell: (props: CellContext<TableData, boolean>) => {
          const value = props.getValue();

          const tooltipProps = value
            ? tooltipProperties.final
            : tooltipProperties.estimated;

          return (
            <div className="flex flex-row-reverse items-center justify-end gap-4">
              <Chip variant={tooltipProps.variant as ChipVariant}>
                <span>{tooltipProps.text ?? "-"}</span>
              </Chip>
            </div>
          );
        },
      }),
      columnHelper.accessor("standard", {
        header: "Standard",
        cell: ChipCell,
      }),
      columnHelper.accessor("creditLevel", {
        header: "Credit Level",
      }),
      columnHelper.accessor("emissionType", {
        header: "Type",
        cell: ChipCell,
      }),
      columnHelper.accessor("quantityType", {
        header: "Quantity Type",
        cell: ChipCell,
      }),
      columnHelper.accessor("initialQuantity", {
        header: "Initial Quantity",
      }),
      columnHelper.accessor("availableQuantity", {
        header: "Available Quantity",
      }),
      columnHelper.accessor("createdAt", {
        cell: DateCell,
        header: "Created At",
      }),
    ];
  }

  function getRowData(
    assets: InventoryWithAssets["assetCalculations"],
  ): TableData[] {
    if (!assets) return [];

    return assets.map(asset => {
      return {
        id: asset.id,
        isFinal: asset.isFinal,
        standard: getCalculationStandardChip(asset.standard) ?? "-",
        availableQuantity: asset.availableQuantity ?? "-",
        emissionType: getEmissionTypeChip(asset.emissionType) ?? "-",
        creditLevel: asset.creditLevel ?? "-",
        initialQuantity: asset.initialQuantity ?? "-",
        quantityType: getCalculationQuantityTypeChip(asset.quantityType) ?? "-",
        createdAt: asset.createdAt,
      };
    });
  }
};

// Move these when they are gonna be used in other files
const calculationStandard: Record<
  CalculationStandard,
  { variant: ChipVariant; label: string }
> = {
  [CalculationStandard.Verra]: { variant: "success", label: "Verra" },
  [CalculationStandard.Iso]: { variant: "info", label: "ISO" },
  [CalculationStandard.SupplyChain]: {
    variant: "neutral",
    label: "Supply Chain",
  },
};

const emissionType: Record<
  EmissionType,
  { variant: ChipVariant; label: string }
> = {
  [EmissionType.Reduction]: { variant: "warning", label: "Reduction" },
  [EmissionType.Removal]: { variant: "success", label: "Removal" },
  [EmissionType.Generic]: { variant: "info", label: "Generic" },
  [EmissionType.Footprint]: { variant: "warning", label: "Footprint" },
};

const calculationQuantityType: Record<
  CalculationQuantityType,
  { variant: ChipVariant; label: string }
> = {
  [CalculationQuantityType.Gross]: { variant: "neutral", label: "Gross" },
  [CalculationQuantityType.Buffer]: { variant: "neutral", label: "Buffer" },
  [CalculationQuantityType.Net]: { variant: "info", label: "Net" },
  [CalculationQuantityType.FarmerNet]: {
    variant: "info",
    label: "Farmer Net",
  },
  [CalculationQuantityType.Fee]: { variant: "success", label: "Fee" },
  [CalculationQuantityType.PrmeiumPool]: {
    variant: "neutral",
    label: "Premium Pool",
  },
  [CalculationQuantityType.Footprint]: {
    variant: "warning",
    label: "Footprint",
  },
};

export const getCalculationStandardChip = (
  type: CalculationStandard,
): ChipCellValue => ({
  variant: calculationStandard[type].variant,
  label: calculationStandard[type].label,
});

export const getEmissionTypeChip = (type: EmissionType): ChipCellValue => ({
  variant: emissionType[type].variant,
  label: emissionType[type].label,
});

export const getCalculationQuantityTypeChip = (
  type: CalculationQuantityType,
): ChipCellValue => ({
  variant: calculationQuantityType[type].variant,
  label: calculationQuantityType[type].label,
});
