import { z } from "zod";

import { Locale } from "@ag/i18n";
import { LegacyUserType, UserRole, UserValidationState } from "@ag/utils/types";

export const UserSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  name: z.string(),
  validationState: z.nativeEnum(UserValidationState),
  startupPage: z.string().nullable(),
  locale: z.nativeEnum(Locale),
  hubspotContactId: z.string().nullable(),
  hubspotContactUrl: z.string().nullable(),
  priority: z.string().nullable(),
  marketplaceFilter: z.any().nullable(),
  confirmedAt: z.string().nullable(),
  createdAt: z.string(),
  validatedAt: z.string().nullable(),
  lastSignInAt: z.string().nullable(),
  lastActivityAt: z.string().nullable(),
  lastContactedAt: z.string().nullable(),
  nextContactAt: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  emailConfirmedAt: z.string().nullable(),
  preferredCurrency: z.string(),
  roles: z.array(
    z.union([z.nativeEnum(UserRole), z.nativeEnum(LegacyUserType)]),
  ),

  company: z
    .object({
      id: z.string(),
      name: z.string(),
      vatNumber: z.string(),
      registrationNumber: z.string().nullable(),
      vatValidatedAt: z.string().nullable(),
      description: z.string().nullable(),
      contractSignerFirstName: z.string().nullable(),
      contractSignerLastName: z.string().nullable(),
      contractSignerEmail: z.string().nullable(),
      contractSignerPhoneNumber: z.string().nullable(),
      contractSignerCountryCode: z.string().nullable(),

      freeTrialStartedAt: z.string().nullable().optional(),
      freeTrialDays: z.number().nullable().optional(),
      agricultureConventional: z.boolean().optional(),
      agricultureOrganic: z.boolean().optional(),
      producerBeef: z.boolean().optional(),
      producerChicken: z.boolean().optional(),
      producerDairy: z.boolean().optional(),
      producerEggs: z.boolean().optional(),
      producerGrain: z.boolean().optional(),
      producerPigs: z.boolean().optional(),

      address: z.object({
        id: z.string(),
        address: z.string(),
        checksum: z.string(),
        city: z.string(),
        name: z.string().nullable(),
        nameOrFallback: z.string(),
        position: z.object({
          lng: z.number(),
          lat: z.number(),
        }),
        originalAddressId: z.string().nullable(),
        zipCode: z.string(),
        countryCode: z.string(),
        country: z.object({
          id: z.string(),
          code: z.string(),
          name: z.string(),
        }),
      }),
    })
    .nullable(),

  freightAndTradeSetting: z
    .object({
      id: z.string(),
      acceptedNewsletter: z.boolean(),
      acceptedTerms: z.boolean(),
      buyer: z.boolean().nullable(),
      preferredCurrency: z.string(),
      productionSizeBeefHeads: z.number().nullable(),
      productionSizeChicks: z.number().nullable(),
      productionSizeDairyHeads: z.number().nullable(),
      productionSizeHectares: z.number().nullable(),
      productionSizeSows: z.number().nullable(),
      productionSizeSlaughter: z.number().nullable(),
      productionSizeLayers: z.number().nullable(),
      seller: z.boolean().nullable(),
      totalFieldsSizeHa: z.number().nullable(),
      tradedTonnesCommoditiesPerYear: z.number().nullable(),
      tradedTonnesGrainPerYear: z.number().nullable(),
    })
    .optional(),

  profile: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      phoneNumber: z.string().nullable(),
    })
    .nullable(),
});

export type User = z.infer<typeof UserSchema>;

export const UserFiltersSchema = z.object({
  id: z.string().optional(),
  search: z.string().optional(),
  validationState: z
    .nativeEnum(UserValidationState)
    .optional()
    .or(z.literal("")),
  haUnderManagementRange: z.string().optional(),
  countryCode: z.string().optional(),
  lastActivity: z.string().optional(),
});

export type UserFilters = z.infer<typeof UserFiltersSchema>;
