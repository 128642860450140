import { z } from "zod";

import { CertificateGroupStatus } from "~features/inventory/entities/inventory";

export enum OwnershipType {
  AgreenaOwner = "agreenaOwned",
  FarmerOwnedResale = "farmerOwnedResale",
  FarmerOwnedNoAgreement = "farmerOwnedNoAgreement",
}

export enum EstimationType {
  Final = "final",
  Estimated = "estimated",
}

const IsoEligibleOnlySchema = z.object({
  isoReductions: z.string(),
  isoRemovals: z.string(),
});

const VerraAndIsoEligibleSchema = z.object({
  isoReductions: z.string(),
  isoRemovals: z.string(),
  verraReductions: z.string(),
  verraRemovals: z.string(),
});

const VerraEligibleOnlySchema = z.object({
  verraReductions: z.string(),
  verraRemovals: z.string(),
});

const PortfolioAggregateSchema = z.object({
  isoEligibleOnly: IsoEligibleOnlySchema,
  verraAndIsoEligible: VerraAndIsoEligibleSchema,
  verraEligibleOnly: VerraEligibleOnlySchema,
});

export const PortfolioSchema = z.object({
  total: PortfolioAggregateSchema,
  countries: z.record(z.string(), PortfolioAggregateSchema),
});

export type Portfolio = z.infer<typeof PortfolioSchema>;

export const PortfolioFiltersSchema = z.object({
  vintageYear: z.string().optional(),
  ownership: z
    .enum([
      OwnershipType.AgreenaOwner,
      OwnershipType.FarmerOwnedResale,
      OwnershipType.FarmerOwnedNoAgreement,
    ])
    .optional()
    .or(z.literal("")),
  availability: z
    .enum([CertificateGroupStatus.SOLD, CertificateGroupStatus.ALLOCATED])
    .optional()
    .or(z.literal("")),
  estimation: z.nativeEnum(EstimationType).optional().or(z.literal("")),
  verraBufferEnabled: z.string().optional(),
});

export type PortfolioFilters = z.infer<typeof PortfolioFiltersSchema>;
