export const COUNTRIES = [
  {
    name: "Andorra",
    code: "AD",
    callingCode: "376",
    flag: "🇦🇩",
  },
  {
    name: "Afghanistan",
    code: "AF",
    callingCode: "93",
    flag: "🇦🇫",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    callingCode: "1268",
    flag: "🇦🇬",
  },
  {
    name: "Anguilla",
    code: "AI",
    callingCode: "1264",
    flag: "🇦🇮",
  },
  {
    name: "Albania",
    code: "AL",
    callingCode: "355",
    flag: "🇦🇱",
  },
  {
    name: "Armenia",
    code: "AM",
    callingCode: "374",
    flag: "🇦🇲",
  },
  {
    name: "Angola",
    code: "AO",
    callingCode: "244",
    flag: "🇦🇴",
  },
  {
    name: "Antarctica",
    code: "AQ",
    callingCode: "672",
    flag: "🇦🇶",
  },
  {
    name: "Argentina",
    code: "AR",
    callingCode: "54",
    flag: "🇦🇷",
  },
  {
    name: "American Samoa",
    code: "AS",
    callingCode: "1684",
    flag: "🇦🇸",
  },
  {
    name: "Austria",
    code: "AT",
    callingCode: "43",
    flag: "🇦🇹",
  },
  {
    name: "Australia",
    code: "AU",
    callingCode: "61",
    flag: "🇦🇺",
  },
  {
    name: "Aruba",
    code: "AW",
    callingCode: "297",
    flag: "🇦🇼",
  },
  {
    name: "Åland Islands",
    code: "AX",
    callingCode: "358",
    flag: "🇦🇽",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    callingCode: "994",
    flag: "🇦🇿",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    callingCode: "387",
    flag: "🇧🇦",
  },
  {
    name: "Barbados",
    code: "BB",
    callingCode: "1246",
    flag: "🇧🇧",
  },
  {
    name: "Bangladesh",
    code: "BD",
    callingCode: "880",
    flag: "🇧🇩",
  },
  {
    name: "Belgium",
    code: "BE",
    callingCode: "32",
    flag: "🇧🇪",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    callingCode: "226",
    flag: "🇧🇫",
  },
  {
    name: "Bulgaria",
    code: "BG",
    callingCode: "359",
    flag: "🇧🇬",
  },
  {
    name: "Bahrain",
    code: "BH",
    callingCode: "973",
    flag: "🇧🇭",
  },
  {
    name: "Burundi",
    code: "BI",
    callingCode: "257",
    flag: "🇧🇮",
  },
  {
    name: "Benin",
    code: "BJ",
    callingCode: "229",
    flag: "🇧🇯",
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
    callingCode: "590",
    flag: "🇧🇱",
  },
  {
    name: "Bermuda",
    code: "BM",
    callingCode: "1441",
    flag: "🇧🇲",
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    callingCode: "673",
    flag: "🇧🇳",
  },
  {
    name: "Bolivia (Plurinational State of)",
    code: "BO",
    callingCode: "591",
    flag: "🇧🇴",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    callingCode: "5997",
    flag: "🇧🇶",
  },
  {
    name: "Brazil",
    code: "BR",
    callingCode: "55",
    flag: "🇧🇷",
  },
  {
    name: "Bhutan",
    code: "BT",
    callingCode: "975",
    flag: "🇧🇹",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    callingCode: "47",
    flag: "🇧🇻",
  },
  {
    name: "Botswana",
    code: "BW",
    callingCode: "267",
    flag: "🇧🇼",
  },
  {
    name: "Belarus",
    code: "BY",
    callingCode: "375",
    flag: "🇧🇾",
  },
  {
    name: "Belize",
    code: "BZ",
    callingCode: "501",
    flag: "🇧🇿",
  },
  {
    name: "Canada",
    code: "CA",
    callingCode: "1",
    flag: "🇨🇦",
  },
  {
    name: "Switzerland",
    code: "CH",
    callingCode: "41",
    flag: "🇨🇭",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    callingCode: "225",
    flag: "🇨🇮",
  },
  {
    name: "Chile",
    code: "CL",
    callingCode: "56",
    flag: "🇨🇱",
  },
  {
    name: "Cameroon",
    code: "CM",
    callingCode: "237",
    flag: "🇨🇲",
  },
  {
    name: "China",
    code: "CN",
    callingCode: "86",
    flag: "🇨🇳",
  },
  {
    name: "Colombia",
    code: "CO",
    callingCode: "57",
    flag: "🇨🇴",
  },
  {
    name: "Costa Rica",
    code: "CR",
    callingCode: "506",
    flag: "🇨🇷",
  },
  {
    name: "Cuba",
    code: "CU",
    callingCode: "53",
    flag: "🇨🇺",
  },
  {
    name: "Cabo Verde",
    code: "CV",
    callingCode: "238",
    flag: "🇨🇻",
  },
  {
    name: "Curaçao",
    code: "CW",
    callingCode: "599",
    flag: "🇨🇼",
  },
  {
    name: "Christmas Island",
    code: "CX",
    callingCode: "61",
    flag: "🇨🇽",
  },
  {
    name: "Cyprus",
    code: "CY",
    callingCode: "357",
    flag: "🇨🇾",
  },
  {
    name: "Germany",
    code: "DE",
    callingCode: "49",
    flag: "🇩🇪",
  },
  {
    name: "Djibouti",
    code: "DJ",
    callingCode: "253",
    flag: "🇩🇯",
  },
  {
    name: "Denmark",
    code: "DK",
    callingCode: "45",
    flag: "🇩🇰",
  },
  {
    name: "Dominica",
    code: "DM",
    callingCode: "767",
    flag: "🇩🇲",
  },
  {
    name: "Algeria",
    code: "DZ",
    callingCode: "213",
    flag: "🇩🇿",
  },
  {
    name: "Ecuador",
    code: "EC",
    callingCode: "593",
    flag: "🇪🇨",
  },
  {
    name: "Estonia",
    code: "EE",
    callingCode: "372",
    flag: "🇪🇪",
  },
  {
    name: "Egypt",
    code: "EG",
    callingCode: "20",
    flag: "🇪🇬",
  },
  {
    name: "Western Sahara",
    code: "EH",
    callingCode: "212",
    flag: "🇪🇭",
  },
  {
    name: "Eritrea",
    code: "ER",
    callingCode: "291",
    flag: "🇪🇷",
  },
  {
    name: "Spain",
    code: "ES",
    callingCode: "34",
    flag: "🇪🇸",
  },
  {
    name: "Ethiopia",
    code: "ET",
    callingCode: "251",
    flag: "🇪🇹",
  },
  {
    name: "Finland",
    code: "FI",
    callingCode: "358",
    flag: "🇫🇮",
  },
  {
    name: "Fiji",
    code: "FJ",
    callingCode: "679",
    flag: "🇫🇯",
  },
  {
    name: "Micronesia (Federated States of)",
    code: "FM",
    callingCode: "691",
    flag: "🇫🇲",
  },
  {
    name: "France",
    code: "FR",
    callingCode: "33",
    flag: "🇫🇷",
  },
  {
    name: "Gabon",
    code: "GA",
    callingCode: "241",
    flag: "🇬🇦",
  },
  {
    name: "Grenada",
    code: "GD",
    callingCode: "1473",
    flag: "🇬🇩",
  },
  {
    name: "Georgia",
    code: "GE",
    callingCode: "995",
    flag: "🇬🇪",
  },
  {
    name: "French Guiana",
    code: "GF",
    callingCode: "594",
    flag: "🇬🇫",
  },
  {
    name: "Guernsey",
    code: "GG",
    callingCode: "44",
    flag: "🇬🇬",
  },
  {
    name: "Ghana",
    code: "GH",
    callingCode: "233",
    flag: "🇬🇭",
  },
  {
    name: "Gibraltar",
    code: "GI",
    callingCode: "350",
    flag: "🇬🇮",
  },
  {
    name: "Greenland",
    code: "GL",
    callingCode: "299",
    flag: "🇬🇱",
  },
  {
    name: "Guinea",
    code: "GN",
    callingCode: "224",
    flag: "🇬🇳",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    callingCode: "590",
    flag: "🇬🇵",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    callingCode: "240",
    flag: "🇬🇶",
  },
  {
    name: "Greece",
    code: "GR",
    callingCode: "30",
    flag: "🇬🇷",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    callingCode: "500",
    flag: "🇬🇸",
  },
  {
    name: "Guatemala",
    code: "GT",
    callingCode: "502",
    flag: "🇬🇹",
  },
  {
    name: "Guam",
    code: "GU",
    callingCode: "1",
    flag: "🇬🇺",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    callingCode: "245",
    flag: "🇬🇼",
  },
  {
    name: "Guyana",
    code: "GY",
    callingCode: "592",
    flag: "🇬🇾",
  },
  {
    name: "Hong Kong",
    code: "HK",
    callingCode: "852",
    flag: "🇭🇰",
  },
  {
    name: "Honduras",
    code: "HN",
    callingCode: "504",
    flag: "🇭🇳",
  },
  {
    name: "Croatia",
    code: "HR",
    callingCode: "385",
    flag: "🇭🇷",
  },
  {
    name: "Haiti",
    code: "HT",
    callingCode: "509",
    flag: "🇭🇹",
  },
  {
    name: "Hungary",
    code: "HU",
    callingCode: "36",
    flag: "🇭🇺",
  },
  {
    name: "Indonesia",
    code: "ID",
    callingCode: "62",
    flag: "🇮🇩",
  },
  {
    name: "Ireland",
    code: "IE",
    callingCode: "353",
    flag: "🇮🇪",
  },
  {
    name: "Israel",
    code: "IL",
    callingCode: "972",
    flag: "🇮🇱",
  },
  {
    name: "Isle of Man",
    code: "IM",
    callingCode: "44",
    flag: "🇮🇲",
  },
  {
    name: "India",
    code: "IN",
    callingCode: "91",
    flag: "🇮🇳",
  },
  {
    name: "British Indian Ocean Territories",
    code: "IO",
    callingCode: "246",
    flag: "🇮🇴",
  },
  {
    name: "Iraq",
    code: "IQ",
    callingCode: "964",
    flag: "🇮🇶",
  },
  {
    name: "Iran (Islamic Republic of)",
    code: "IR",
    callingCode: "98",
    flag: "🇮🇷",
  },
  {
    name: "Iceland",
    code: "IS",
    callingCode: "354",
    flag: "🇮🇸",
  },
  {
    name: "Italy",
    code: "IT",
    callingCode: "39",
    flag: "🇮🇹",
  },
  {
    name: "Jersey",
    code: "JE",
    callingCode: "44",
    flag: "🇯🇪",
  },
  {
    name: "Jamaica",
    code: "JM",
    callingCode: "876",
    flag: "🇯🇲",
  },
  {
    name: "Jordan",
    code: "JO",
    callingCode: "962",
    flag: "🇯🇴",
  },
  {
    name: "Japan",
    code: "JP",
    callingCode: "81",
    flag: "🇯🇵",
  },
  {
    name: "Kenya",
    code: "KE",
    callingCode: "254",
    flag: "🇰🇪",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    callingCode: "996",
    flag: "🇰🇬",
  },
  {
    name: "Cambodia",
    code: "KH",
    callingCode: "855",
    flag: "🇰🇭",
  },
  {
    name: "North Korea",
    code: "KP",
    callingCode: "850",
    flag: "🇰🇵",
  },
  {
    name: "South Korea",
    code: "KR",
    callingCode: "82",
    flag: "🇰🇷",
  },
  {
    name: "Kiribati",
    code: "KI",
    callingCode: "686",
    flag: "🇰🇮",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    callingCode: "1869",
    flag: "🇰🇳",
  },
  {
    name: "Kuwait",
    code: "KW",
    callingCode: "965",
    flag: "🇰🇼",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    callingCode: "7",
    flag: "🇰🇿",
  },
  {
    name: "Lebanon",
    code: "LB",
    callingCode: "961",
    flag: "🇱🇧",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    callingCode: "1758",
    flag: "🇱🇨",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    callingCode: "423",
    flag: "🇱🇮",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    callingCode: "94",
    flag: "🇱🇰",
  },
  {
    name: "Liberia",
    code: "LR",
    callingCode: "231",
    flag: "🇱🇷",
  },
  {
    name: "Lesotho",
    code: "LS",
    callingCode: "266",
    flag: "🇱🇸",
  },
  {
    name: "Lithuania",
    code: "LT",
    callingCode: "370",
    flag: "🇱🇹",
  },
  {
    name: "Luxembourg",
    code: "LU",
    callingCode: "352",
    flag: "🇱🇺",
  },
  {
    name: "Latvia",
    code: "LV",
    callingCode: "371",
    flag: "🇱🇻",
  },
  {
    name: "Libya",
    code: "LY",
    callingCode: "218",
    flag: "🇱🇾",
  },
  {
    name: "Morocco",
    code: "MA",
    callingCode: "212",
    flag: "🇲🇦",
  },
  {
    name: "Monaco",
    code: "MC",
    callingCode: "377",
    flag: "🇲🇨",
  },
  {
    name: "Montenegro",
    code: "ME",
    callingCode: "382",
    flag: "🇲🇪",
  },
  {
    name: "Saint Martin (French part)",
    code: "MF",
    callingCode: "590",
    flag: "🇲🇫",
  },
  {
    name: "Madagascar",
    code: "MG",
    callingCode: "261",
    flag: "🇲🇬",
  },
  {
    name: "Mali",
    code: "ML",
    callingCode: "223",
    flag: "🇲🇱",
  },
  {
    name: "Myanmar",
    code: "MM",
    callingCode: "95",
    flag: "🇲🇲",
  },
  {
    name: "Mongolia",
    code: "MN",
    callingCode: "976",
    flag: "🇲🇳",
  },
  {
    name: "Macao",
    code: "MO",
    callingCode: "853",
    flag: "🇲🇴",
  },
  {
    name: "Martinique",
    code: "MQ",
    callingCode: "596",
    flag: "🇲🇶",
  },
  {
    name: "Mauritania",
    code: "MR",
    callingCode: "222",
    flag: "🇲🇷",
  },
  {
    name: "Montserrat",
    code: "MS",
    callingCode: "1664",
    flag: "🇲🇸",
  },
  {
    name: "Malta",
    code: "MT",
    callingCode: "356",
    flag: "🇲🇹",
  },
  {
    name: "Mauritius",
    code: "MU",
    callingCode: "230",
    flag: "🇲🇺",
  },
  {
    name: "Maldives",
    code: "MV",
    callingCode: "960",
    flag: "🇲🇻",
  },
  {
    name: "Malawi",
    code: "MW",
    callingCode: "265",
    flag: "🇲🇼",
  },
  {
    name: "Mexico",
    code: "MX",
    callingCode: "52",
    flag: "🇲🇽",
  },
  {
    name: "Malaysia",
    code: "MY",
    callingCode: "60",
    flag: "🇲🇾",
  },
  {
    name: "Mozambique",
    code: "MZ",
    callingCode: "258",
    flag: "🇲🇿",
  },
  {
    name: "Namibia",
    code: "NA",
    callingCode: "264",
    flag: "🇳🇦",
  },
  {
    name: "New Caledonia",
    code: "NC",
    callingCode: "687",
    flag: "🇳🇨",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    callingCode: "672",
    flag: "🇳🇫",
  },
  {
    name: "Nigeria",
    code: "NG",
    callingCode: "234",
    flag: "🇳🇬",
  },
  {
    name: "Nicaragua",
    code: "NI",
    callingCode: "505",
    flag: "🇳🇮",
  },
  {
    name: "Norway",
    code: "NO",
    callingCode: "47",
    flag: "🇳🇴",
  },
  {
    name: "Nepal",
    code: "NP",
    callingCode: "977",
    flag: "🇳🇵",
  },
  {
    name: "Nauru",
    code: "NR",
    callingCode: "674",
    flag: "🇳🇷",
  },
  {
    name: "Niue",
    code: "NU",
    callingCode: "683",
    flag: "🇳🇺",
  },
  {
    name: "New Zealand",
    code: "NZ",
    callingCode: "64",
    flag: "🇳🇿",
  },
  {
    name: "Oman",
    code: "OM",
    callingCode: "968",
    flag: "🇴🇲",
  },
  {
    name: "Panama",
    code: "PA",
    callingCode: "507",
    flag: "🇵🇦",
  },
  {
    name: "Peru",
    code: "PE",
    callingCode: "51",
    flag: "🇵🇪",
  },
  {
    name: "French Polynesia",
    code: "PF",
    callingCode: "689",
    flag: "🇵🇫",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    callingCode: "675",
    flag: "🇵🇬",
  },
  {
    name: "Pakistan",
    code: "PK",
    callingCode: "92",
    flag: "🇵🇰",
  },
  {
    name: "Poland",
    code: "PL",
    callingCode: "48",
    flag: "🇵🇱",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    callingCode: "508",
    flag: "🇵🇲",
  },
  {
    name: "Pitcairn",
    code: "PN",
    callingCode: "64",
    flag: "🇵🇳",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    callingCode: "1",
    flag: "🇵🇷",
  },
  {
    name: "Palestine, State of",
    code: "PS",
    callingCode: "970",
    flag: "🇵🇸",
  },
  {
    name: "Portugal",
    code: "PT",
    callingCode: "351",
    flag: "🇵🇹",
  },
  {
    name: "Palau",
    code: "PW",
    callingCode: "680",
    flag: "🇵🇼",
  },
  {
    name: "Paraguay",
    code: "PY",
    callingCode: "595",
    flag: "🇵🇾",
  },
  {
    name: "Qatar",
    code: "QA",
    callingCode: "974",
    flag: "🇶🇦",
  },
  {
    name: "Réunion",
    code: "RE",
    callingCode: "262",
    flag: "🇷🇪",
  },
  {
    name: "Romania",
    code: "RO",
    callingCode: "40",
    flag: "🇷🇴",
  },
  {
    name: "Serbia",
    code: "RS",
    callingCode: "381",
    flag: "🇷🇸",
  },
  {
    name: "Russia",
    code: "RU",
    callingCode: "7",
    flag: "🇷🇺",
  },
  {
    name: "Rwanda",
    code: "RW",
    callingCode: "250",
    flag: "🇷🇼",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    callingCode: "966",
    flag: "🇸🇦",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    callingCode: "677",
    flag: "🇸🇧",
  },
  {
    name: "Seychelles",
    code: "SC",
    callingCode: "248",
    flag: "🇸🇨",
  },
  {
    name: "Sweden",
    code: "SE",
    callingCode: "46",
    flag: "🇸🇪",
  },
  {
    name: "Singapore",
    code: "SG",
    callingCode: "65",
    flag: "🇸🇬",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    callingCode: "290",
    flag: "🇸🇭",
  },
  {
    name: "Slovenia",
    code: "SI",
    callingCode: "386",
    flag: "🇸🇮",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    callingCode: "4779",
    flag: "🇸🇯",
  },
  {
    name: "Slovakia",
    code: "SK",
    callingCode: "421",
    flag: "🇸🇰",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    callingCode: "232",
    flag: "🇸🇱",
  },
  {
    name: "Republic of San Marino",
    code: "SM",
    callingCode: "378",
    flag: "🇸🇲",
  },
  {
    name: "Senegal",
    code: "SN",
    callingCode: "221",
    flag: "🇸🇳",
  },
  {
    name: "Somalia",
    code: "SO",
    callingCode: "252",
    flag: "🇸🇴",
  },
  {
    name: "Suriname",
    code: "SR",
    callingCode: "597",
    flag: "🇸🇷",
  },
  {
    name: "South Sudan",
    code: "SS",
    callingCode: "211",
    flag: "🇸🇸",
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    callingCode: "239",
    flag: "🇸🇹",
  },
  {
    name: "El Salvador",
    code: "SV",
    callingCode: "503",
    flag: "🇸🇻",
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SX",
    callingCode: "1721",
    flag: "🇸🇽",
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    callingCode: "963",
    flag: "🇸🇾",
  },
  {
    name: "Chad",
    code: "TD",
    callingCode: "235",
    flag: "🇹🇩",
  },
  {
    name: "Togo",
    code: "TG",
    callingCode: "228",
    flag: "🇹🇬",
  },
  {
    name: "Thailand",
    code: "TH",
    callingCode: "66",
    flag: "🇹🇭",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    callingCode: "992",
    flag: "🇹🇯",
  },
  {
    name: "Tokelau",
    code: "TK",
    callingCode: "690",
    flag: "🇹🇰",
  },
  {
    name: "Timor-Leste",
    code: "TL",
    callingCode: "670",
    flag: "🇹🇱",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    callingCode: "993",
    flag: "🇹🇲",
  },
  {
    name: "Tunisia",
    code: "TN",
    callingCode: "216",
    flag: "🇹🇳",
  },
  {
    name: "Tonga",
    code: "TO",
    callingCode: "676",
    flag: "🇹🇴",
  },
  {
    name: "Turkey",
    code: "TR",
    callingCode: "90",
    flag: "🇹🇷",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    callingCode: "868",
    flag: "🇹🇹",
  },
  {
    name: "Tuvalu",
    code: "TV",
    callingCode: "688",
    flag: "🇹🇻",
  },
  {
    name: "United Republic of Tanzania",
    code: "TZ",
    callingCode: "255",
    flag: "🇹🇿",
  },
  {
    name: "Ukraine",
    code: "UA",
    callingCode: "380",
    flag: "🇺🇦",
  },
  {
    name: "Uganda",
    code: "UG",
    callingCode: "256",
    flag: "🇺🇬",
  },
  {
    name: "United States of America",
    code: "US",
    callingCode: "1",
    flag: "🇺🇸",
  },
  {
    name: "Uruguay",
    code: "UY",
    callingCode: "598",
    flag: "🇺🇾",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    callingCode: "998",
    flag: "🇺🇿",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    callingCode: "1784",
    flag: "🇻🇨",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    code: "VE",
    callingCode: "58",
    flag: "🇻🇪",
  },
  {
    name: "Virgin Islands (British)",
    code: "VG",
    callingCode: "1284",
    flag: "🇻🇬",
  },
  {
    name: "Virgin Islands (U.S.)",
    code: "VI",
    callingCode: "1340",
    flag: "🇻🇮",
  },
  {
    name: "Vietnam",
    code: "VN",
    callingCode: "84",
    flag: "🇻🇳",
  },
  {
    name: "Vanuatu",
    code: "VU",
    callingCode: "678",
    flag: "🇻🇺",
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    callingCode: "681",
    flag: "🇼🇫",
  },
  {
    name: "Samoa",
    code: "WS",
    callingCode: "685",
    flag: "🇼🇸",
  },
  {
    name: "Yemen",
    code: "YE",
    callingCode: "967",
    flag: "🇾🇪",
  },
  {
    name: "Mayotte",
    code: "YT",
    callingCode: "262",
    flag: "🇾🇹",
  },
  {
    name: "South Africa",
    code: "ZA",
    callingCode: "27",
    flag: "🇿🇦",
  },
  {
    name: "Zambia",
    code: "ZM",
    callingCode: "260",
    flag: "🇿🇲",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    callingCode: "263",
    flag: "🇿🇼",
  },
  {
    name: "Eswatini",
    code: "SZ",
    callingCode: "268",
    flag: "🇸🇿",
  },
  {
    name: "North Macedonia",
    code: "MK",
    callingCode: "389",
    flag: "🇲🇰",
  },
  {
    name: "Philippines",
    code: "PH",
    callingCode: "63",
    flag: "🇵🇭",
  },
  {
    name: "Netherlands",
    code: "NL",
    callingCode: "31",
    flag: "🇳🇱",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    callingCode: "971",
    flag: "🇦🇪",
  },
  {
    name: "Republic of Moldova",
    code: "MD",
    callingCode: "373",
    flag: "🇲🇩",
  },
  {
    name: "Gambia",
    code: "GM",
    callingCode: "220",
    flag: "🇬🇲",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    callingCode: "1",
    flag: "🇩🇴",
  },
  {
    name: "Sudan",
    code: "SD",
    callingCode: "249",
    flag: "🇸🇩",
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
    callingCode: "856",
    flag: "🇱🇦",
  },
  {
    name: "Taiwan, Province of China",
    code: "TW",
    callingCode: "886",
    flag: "🇹🇼",
  },
  {
    name: "Republic of the Congo",
    code: "CG",
    callingCode: "242",
    flag: "🇨🇬",
  },
  {
    name: "Czechia",
    code: "CZ",
    callingCode: "420",
    flag: "🇨🇿",
  },
  {
    name: "United Kingdom",
    code: "GB",
    callingCode: "44",
    flag: "🇬🇧",
  },
  {
    name: "Niger",
    code: "NE",
    callingCode: "227",
    flag: "🇳🇪",
  },
  {
    name: "Democratic Republic of the Congo",
    code: "CD",
    callingCode: "243",
    flag: "🇨🇩",
  },
  {
    name: "Commonwealth of The Bahamas",
    code: "BS",
    callingCode: "1 242",
    flag: "🇧🇸",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    callingCode: "61 891",
    flag: "🇨🇨",
  },
  {
    name: "Central African Republic",
    code: "CF",
    callingCode: "236",
    flag: "🇨🇫",
  },
  {
    name: "Cook Islands",
    code: "CK",
    callingCode: "682",
    flag: "🇨🇰",
  },
  {
    name: "Falkland Islands",
    code: "FK",
    callingCode: "500",
    flag: "🇫🇰",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    callingCode: "298",
    flag: "🇫🇴",
  },
  {
    name: "Territory of Heard Island and McDonald Islands",
    code: "HM",
    callingCode: "672",
    flag: "🇭🇲",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    callingCode: "246",
    flag: "🇮🇴",
  },
  {
    name: "Comoros",
    code: "KM",
    callingCode: "269",
    flag: "🇰🇲",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    callingCode: "1 345",
    flag: "🇰🇾",
  },
  {
    name: "Republic of the Marshall Islands",
    code: "MH",
    callingCode: "692",
    flag: "🇲🇭",
  },
  {
    name: "Commonwealth of the Northern Mariana Islands",
    code: "MP",
    callingCode: "1 670",
    flag: "🇲🇵",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    callingCode: "1 649",
    flag: "🇹🇨",
  },
  {
    name: "French Southern and Antarctic Lands",
    code: "TF",
    callingCode: "672",
    flag: "🇹🇫",
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    callingCode: "1",
    flag: "🇺🇲",
  },
  {
    name: "Holy See",
    code: "VA",
    callingCode: "39",
    flag: "🇻🇦",
  },
  {
    name: "Republic of Kosovo",
    code: "XK",
    callingCode: "383",
    flag: "🇽🇰",
  },
  {
    name: "Netherlands Antilles",
    code: "AN",
    callingCode: "599",
    flag: "🇧🇶",
  },
];

export function getCountryWithFlag(countryNameOrCode: string | undefined) {
  if (!countryNameOrCode) {
    return { name: "", flag: "" };
  }
  const country = COUNTRIES.find(
    c =>
      c.name.toLowerCase() === countryNameOrCode.toLowerCase() ||
      c.code.toLowerCase() === countryNameOrCode.toLowerCase(),
  );

  if (!country) {
    return { name: countryNameOrCode, flag: "" };
  }

  return { ...country };
}
