import { z } from "zod";

import {
  FieldActualsInputType,
  FieldActualsStatus,
  FieldDefinitionStatus,
  MethodologyVersion,
} from "@ag/carbon/types";

import { RemovalReason } from "~features/contract/entities/contract";

import { QualityControlStatus } from "../types/field-actuals";
import { FieldValidationStatus } from "../types/validation";

export const FieldDefinitionSimplifiedSchema = z.object({
  baseline: z.number().nullable(),
  completedAt: z.string().nullable(),
  completionRate: z.number(),
  id: z.string(),
  latestHarvestYear: z.string().optional(),
  readonly: z.boolean(),
  simplified: z.boolean(),
  status: z.nativeEnum(FieldDefinitionStatus),
  submittedAt: z.string().nullable(),
  updatedAt: z.string().optional(),
});

export const FieldActualsSimplifiedSchema = z.object({
  carbonFieldActualInputType: z.nativeEnum(FieldActualsInputType),
  completedAt: z.string().nullable(),
  completionRate: z.number(),
  createdAt: z.string(),
  fallow: z.boolean(),
  harvestYear: z.number(),
  id: z.string(),
  methodologyVersion: z.nativeEnum(MethodologyVersion),
  qcStatus: z.nativeEnum(QualityControlStatus).nullable(),
  readonly: z.boolean(),
  status: z.nativeEnum(FieldActualsStatus),
  submittedAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
});

const FieldUserSimplifiedSchema = z.object({
  company: z.object({
    id: z.string(),
    name: z.string(),
  }),
  email: z.string(),
  id: z.string(),
  name: z.string().optional(),
});

export const FieldOverviewSchema = z.object({
  activeCarbonContractId: z.string().nullable(),
  carbonFarmId: z.string(),
  carbonFieldDefinition: FieldDefinitionSimplifiedSchema,
  carbonFieldActuals: z.array(FieldActualsSimplifiedSchema).optional(),
  carbonUserId: z.string(),
  completionRate: z.number(),
  createdAt: z.string(),
  dummy: z.boolean(),
  expiredAt: z.string().nullable(),
  id: z.string(),
  name: z.string(),
  sizeHa: z.number(),
  uncontractedField: z
    .object({
      carbonContractId: z.string(),
      carbonFieldId: z.string(),
      churnedAt: z.string().nullable(),
      hasDocument: z.boolean(),
      id: z.string(),
      removalReason: z.nativeEnum(RemovalReason),
    })
    .nullable(),
  user: FieldUserSimplifiedSchema,
  validationStatus: z.nativeEnum(FieldValidationStatus),
  // TODO: remove under https://agreena-aps.atlassian.net/browse/FARMERX-2651
  _tempIsWhitelistedForUnlocking: z.boolean().optional(),
});

export type FieldOverview = z.infer<typeof FieldOverviewSchema>;
export type FieldUserSimplified = z.infer<typeof FieldUserSimplifiedSchema>;
